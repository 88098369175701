<script>
export default {
  name: 'status-label',
  props: ['status']
}
</script>

<template>
  <div class="d-inline-block">
    <el-tag type="primary" size="mini" v-if="status === 'active'">Ativo</el-tag>
    <el-tag type="primary" size="mini" v-if="status === 'created'">Criado</el-tag>
    <el-tag type="danger" size="mini" v-if="status === 'inactive'">Inativo</el-tag>
    <el-tag type="danger" size="mini" v-if="status === 'cancelled'">Cancelado</el-tag>
    <el-tag type="danger" size="mini" v-if="status === 'blocked'">Bloqueado</el-tag>
    <el-tag type="success" size="mini" v-if="status === 'paid'">Pago</el-tag>
    <el-tag type="warning" size="mini" v-if="status === 'trial_time'">Período de Testes</el-tag>
  </div>
</template>
