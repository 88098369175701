<script>
import * as restfulService from "../../../../services/restfulService";

import bus from '@utils/bus'

export default {
  name: 'auth-button',
  props: {
    label: {
      type: String,
      default: 'Entrar no Clube'
    },
    tenantId: Number,
    tenantUserId: Number,
  },
  data() {
    return {
      isLoadingData: false,
    }
  },
  methods: {
    onAuth() {
      this.isLoadingData = false
      bus.$emit('show-loader')
      restfulService.post('tenant_user', 'admin-auth', null, {
        tenant_id: this.tenantId,
        tenant_user_id: this.tenantUserId
      })
          .then(response => {
            let pom = document.createElement('a')
            pom.setAttribute('target', '_blank')
            pom.setAttribute('href', response.link)
            pom.style.display = 'none'
            document.body.appendChild(pom)
            pom.click()
            document.body.removeChild(pom)
          })
          .catch(e => {
            console.log(e)
          })
          .finally(e => {
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    }
  }
}
</script>

<template>
  <el-button type="default" size="mini" class="mt-1" @click="onAuth">
    <i class="fas fa-user"></i>
    <span class="ml-1">{{label}}</span>
  </el-button>
</template>
