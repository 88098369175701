<script>
  import ListHeader from '../../../../components/list/Header.vue'
  import FormSaveButton from '../../../../components/form/SaveButton.vue'
  import FormCancelButton from '../../../../components/form/CancelButton.vue'

  import * as restfulService from '../../../../services/restfulService'
  import * as notifyService from '../../../../services/notifyService'

  import bus from '@utils/bus'

  import {required, minLength, maxLength} from 'vuelidate/lib/validators'
  import {clone} from 'lodash'

  export default {
    components: {ListHeader, FormSaveButton, FormCancelButton},
    data () {
      return {
        isEdit: false,
        isSubmitted: false,
        isLoadingData: true,
        form: {
          name: '',
          initials: ''
        },
        dependencies: {}
      }
    },
    validations: {
      form: {
        name: {required},
        initials: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(2)
        }
      }
    },
    mounted () {
      const id = this.$route.params.id || null
      if (id) {
        this.isEdit = true
        restfulService.get('state', null, id)
          .then(response => {
            this.form = response
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
      } else {
        this.isLoadingData = false
        bus.$emit('hide-loader')
      }
    },
    methods: {
      onSend () {
        this.isSubmitted = true
        const data = clone(this.form)
        restfulService.save('state', null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'state.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
      }
    }
  }
</script>

<template>
    <div v-if="!isLoadingData">

        <ListHeader title="Estados"/>

        <el-card class="el-card__save_form box-card">
            <div slot="header">
                <span>Dados dos Estados</span>
            </div>
            <el-form ref="form"
                     :model="form"
                     label-width="120px"
                     v-on:submit.prevent="onSend">
                <el-form-item label="Nome"
                              class="is-required"
                              v-bind:class="{ 'el-form-item--error': $v.form.name.$error }">
                    <el-input v-model="form.name"
                              @input="$v.form.name.$touch()"/>
                    <div class="el-form-item__error" v-if="$v.form.name.$error">Esse campo é obrigatório</div>
                </el-form-item>
                <el-form-item label="UF"
                              class="is-required"
                              v-bind:class="{ 'el-form-item--error': $v.form.initials.$error }">
                    <el-input v-model="form.initials"
                              :maxlength="2" :minlength="2"
                              @input="$v.form.initials.$touch()"/>
                    <div class="el-form-item__error" v-if="$v.form.initials.$error">
                        <span v-if="!$v.form.initials.required">Esse campo é obrigatório</span>
                        <span v-if="!$v.form.initials.minLength">Esse campo requer 2 digitos</span>
                    </div>
                </el-form-item>
                <el-form-item>
                    <form-save-button :event-click="onSend"
                                      :is-disabled="isSubmitted || $v.$invalid"
                                      :is-submitted="isSubmitted"/>
                    <form-cancel-button route-name="state.index"/>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
