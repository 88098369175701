<script>
  export default {
    name: 'form-save-button',
    props: ['eventClick', 'isDisabled', 'isSubmitted'],
    methods: {
      onSend () {
        this.eventClick()
      }
    }
  }
</script>

<template>
    <el-button type="success"
               @click="onSend"
               :disabled="isDisabled"
               :loading="isSubmitted">
        <i class="fas fa-check"></i>
        <span class="ml-1">{{isSubmitted ? 'Salvando' : 'Salvar'}} Alterações</span>
    </el-button>
</template>
