import './assets/sass/element-variables.scss'
import 'sweetalert2/src/sweetalert2.scss'

import Vue from 'vue'
import App from './app/components/main/Main.vue'
import router from './router'
import store from './vuex'
import http from './http'
import ElementUI from 'element-ui'
import ptBr from 'element-ui/lib/locale/lang/pt-br'
import locale from 'element-ui/lib/locale'
import Vuelidate from 'vuelidate'
import VueMoney from 'v-money'
import VueTheMask from 'vue-the-mask'

import './app/filters'

Vue.use(http, {router})
Vue.use(ElementUI)
locale.use(ptBr)
Vue.use(Vuelidate)
Vue.use(VueMoney)
Vue.use(VueTheMask)

/**
 * Create a Vue instance
 */
/* eslint-disable no-new */
new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
})
