import axios from 'axios'
import * as restfulService from './restfulService'

export default {
  get (domains) {
    const promises = []
    const responses = {}
    if (domains !== undefined && domains.length > 0) {
      domains.forEach(function (val) {
        if (val !== undefined) {
          val.route = val.route || null
          let defaultData = {
            orderBy: [
              {
                column: 'name',
                direction: 'ASC'
              }
            ]
          }
          let data = val.data || defaultData
          const columns = val.columns || null
          const relations = val.relations || null
          let list = []
          switch (val.domain) {

            case 'role':
              promises.push(restfulService.search('role', val.route, data, columns, relations, 1, 2000))
              responses.roles = []
              break

            case 'permission':
              promises.push(restfulService.search('permission', val.route, data, columns, relations, 1, 2000))
              responses.permissions = []
              break

            case 'client':
              if (!data.orderBy) {
                data.orderBy = [
                  {
                    column: 'name',
                    direction: 'ASC'
                  }
                ]
              }
              promises.push(restfulService.search('client', val.route, data, columns, relations, 1, 500))
              responses.clients = []
              break

            case 'supplier':
              if (!data.orderBy) {
                data.orderBy = [
                  {
                    column: 'name',
                    direction: 'ASC'
                  }
                ]
              }
              promises.push(restfulService.search('supplier', val.route, data, columns, relations, 1, 500))
              responses.suppliers = []
              break

            case 'classification_expense':
              data = {
                type: 'expense',
                orderBy: [
                  {
                    column: 'description',
                    direction: 'ASC'
                  }
                ]
              }
              promises.push(restfulService.search('entry_classification', val.route, data, columns, relations, 1, 500))
              responses.classifications = []
              break

            case 'classification_revenue':
              data = {
                type: 'revenue',
                orderBy: [
                  {
                    column: 'description',
                    direction: 'ASC'
                  }
                ]
              }
              promises.push(restfulService.search('entry_classification', val.route, data, columns, relations, 1, 500))
              responses.classifications = []
              break

            case 'payment_method':
              data = {
                orderBy: [
                  {
                    column: 'order',
                    direction: 'ASC'
                  },
                  {
                    column: 'description',
                    direction: 'ASC'
                  }
                ]
              }
              promises.push(restfulService.search('entry_payment_method', val.route, data, columns, relations, 1, 500))
              responses.payment_methods = []
              break

            case 'portion':
              list = []
              for (let i = 1; i < 20; i++) {
                list.push({
                  id: i,
                  value: i
                })
              }
              promises.push(new Promise(resolve => resolve(list)))
              responses.portions = []
              break

            case 'interval_type':
              list = []
              list.push({id: 'days', value: 'Dia(s)'})
              list.push({id: 'months', value: 'Mês(es)'})
              list.push({id: 'years', value: 'Ano(s)'})
              promises.push(new Promise(resolve => resolve(list)))
              responses.interval_types = []
              break

            case 'status':
              list = [
                {id: 'created', name: 'Aberto'},
                {id: 'settled', name: 'Quitado'}
              ]
              promises.push(new Promise(resolve => resolve(list)))
              responses.status = []
              break

            case 'city':
              promises.push(restfulService.search('city', val.route, data, null, ['state'], 1, 2000))
              responses.cities = []
              break

            case 'state':
              promises.push(restfulService.search('state', val.route, data, columns, relations, 1, 2000))
              responses.states = []
              break

            case 'country':
              promises.push(restfulService.search('country', val.route, data, columns, relations, 1, 2000))
              responses.countries = []
              break

            case 'player_class':
              data = {
                orderBy: [
                  {
                    column: 'sex',
                    direction: 'ASC'
                  },
                  {
                    column: 'name',
                    direction: 'ASC'
                  }
                ]
              }
              promises.push(restfulService.search('player_class', val.route, data, columns, relations, 1, 2000))
              responses.players_class = []
              break

            case 'club':
              promises.push(restfulService.search('club', val.route, data, columns, relations, 1, 2000))
              responses.clubs = []
              break

            case 'module':
              promises.push(restfulService.search('module', val.route, data, columns, relations, 1, 2000))
              responses.modules = []
              break

            case 'bank':
              promises.push(restfulService.search('bank', val.route, data, columns, relations, 1, 2000))
              responses.banks = []
              break

            case 'player_class_sex':
              list = [
                {id: 'male', name: 'Masculina'},
                {id: 'female', name: 'Feminina'},
                {id: 'mixed', name: 'Mista'},
                {id: 'child', name: 'Infantil'},
                {id: 'free', name: 'Livre'},
              ]
              promises.push(new Promise(resolve => resolve(list)))
              responses.player_class_sex = []
              break

            case 'nf_regime_tributario':
              list = [
                {id: 1, name: 'Simples Nacional'},
                {id: 2, name: 'Simples Nacional - Excesso de sublimite de receita bruta'},
                {id: 3, name: 'Regime Normal'},
              ]
              promises.push(new Promise(resolve => resolve(list)))
              responses.nf_regime_tributario = []
              break

            case 'nf_regime_especial_tributacao':
              list = [
                {id: 1, name: 'Microempresa'},
                {id: 2, name: 'Estimativa'},
                {id: 3, name: 'Sociedade de profissionais'},
                {id: 4, name: 'Cooperativa'},
                {id: 5, name: 'MEI - Simples Nacional'},
                {id: 6, name: 'ME EPP- Simples Nacional'},
              ]
              promises.push(new Promise(resolve => resolve(list)))
              responses.nf_regime_especial_tributacao = []
              break

          }
        }
      })
    }

    let i = 0

    const data = axios.all(promises)
      .then((result) => {
        for (let index in responses) {
          if (result[i] !== undefined) {
            if (result[i].data !== undefined) {
              responses[index] = result[i].data
            } else {
              responses[index] = result[i]
            }
          } else {
            responses[index] = []
          }
          i++
        }
        return responses
      })

    return new Promise((resolve) => {
      resolve(data)
    })
  }
}
