<script>
  import ListHeader from '../../../../components/list/Header.vue'
  import FormSaveButton from '../../../../components/form/SaveButton.vue'
  import FormCancelButton from '../../../../components/form/CancelButton.vue'
  import ValidationMessage from '../../../../components/form/ValidationMessage.vue'

  import * as restfulService from '../../../../services/restfulService'
  import * as notifyService from '../../../../services/notifyService'
  import DataService from '../../../../services/dataService'

  import helpers from '@mixins/helpers'
  import bus from '@utils/bus'

  import {required, requiredIf} from 'vuelidate/lib/validators'
  import {cloneDeep} from 'lodash'
  import moment from 'moment'

  export default {
    components: {ListHeader, FormSaveButton, FormCancelButton, ValidationMessage},
    mixins: [helpers],
    props: ['simpleEntry'],
    data () {
      return {
        isEdit: false,
        isSubmitted: false,
        isLoadingData: true,
        isSearchingProduct: false,
        isResetingPortions: false,
        form: {
          type: 'revenue',
          classification_id: '',
          payment_method_id: '',
          client_id: '',
          status: 'created',
          portions_number: 1,
          portions: [],
          amount: 0,
          due_date: this.dateNow('en'),
          interval: 1,
          interval_type: 'months',
          recurrent: false,
          generate_balance: false,
          is_paid: false,
          pay_date: ''
        },
        dependencies: {
          classifications: [],
          payment_methods: [],
          clients: []
        },
        moneyMask: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          precision: 2,
          masked: false
        }
      }
    },
    validations: {
      form: {
        classification_id: {required},
        client_id: {required},
        amount: {required},
        due_date: {required},
        pay_date: {
          required: requiredIf(function () {
            return this.simpleEntry && this.form.is_paid
          })
        }
      }
    },
    mounted () {
      this.getDependencies()
      const id = this.$route.params.id || null
      if (id) {
        this.isEdit = true
        restfulService.get('entry', null, id, null, ['portions'])
          .then(response => {
            this.form = response
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
      } else {
        this.isLoadingData = false
        bus.$emit('hide-loader')
      }
    },
    computed: {
      checkRecurrent () {
        if (this.form.recurrent) {
          this.form.portions_number = 1
        }
        return this.form.recurrent
      },
      checkPortions () {
        return [this.form.portions_number, this.form.due_date, this.form.amount, Date.now()].join()
      }
    },
    watch: {
      checkPortions () {
        this.calculatePortions()
      },
      'form.generate_balance': function (newValue) {
        if (newValue) {
          this.form.is_paid = true
        }
      },
      'form.is_paid': function (newValue) {
        if (newValue && this.form.pay_date.length === 0) {
          this.form.pay_date = this.dateNow('en')
        } else {
          this.form.pay_date = ''
        }
      }
    },
    methods: {
      getDependencies () {
        DataService.get([{domain: 'client'}, {domain: 'classification_revenue'}, {domain: 'payment_method'}, {domain: 'portion'}, {domain: 'interval_type'}]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
        })
      },
      calculatePortions () {
        let dueDate = this.form.due_date
        let amount = this.currencyBrToEn(this.form.amount)
        let portionsNumber = this.form.portions_number
        if (dueDate.length > 0 && amount > 0 && portionsNumber > 0) {
          let amountPortion = this.roundLower(amount / portionsNumber, 2)
          let amountFirstPortion = (Math.round(((amount - (amountPortion * portionsNumber)) * 100)) / 100) + amountPortion
          let intervalType = this.form.interval_type
          let intervalValue = this.form.interval
          let dateMoment = moment(dueDate, 'YYYY-MM-DD').subtract(intervalValue, intervalType)
          this.resetPortions()
          this.form.portions.splice(0)
          for (let i = 1; i <= portionsNumber; i++) {
            this.form.portions.push({
              portion: i,
              description: 'Parcela ' + i + ' de ' + portionsNumber,
              amount: this.currencyEnToBr(i === 1 ? amountFirstPortion : amountPortion),
              due_date: dateMoment.add(intervalValue, intervalType).format('YYYY-MM-DD')
            })
          }
        }
      },
      recalculatePortions (index) {
        let dueDate = this.form.due_date
        let amount = this.currencyBrToEn(this.form.amount)
        let portionsNumber = this.form.portions_number
        if (dueDate.length > 0 && amount > 0 && portionsNumber > 0) {
          let portions = this.form.portions
          let totalBeforeIndex = 0
          let currentPortionAmount = this.currencyBrToEn(portions[index].amount)
          for (let i = 0; i < index; i++) {
            totalBeforeIndex += this.currencyBrToEn(portions[i].amount)
          }
          let portionsToRegenerate = portionsNumber - (index + 1)
          let amountPortion = this.roundLower(((amount - totalBeforeIndex - currentPortionAmount) / portionsToRegenerate), 2)
          let amountFirstPortion = Math.round(((amount - (amountPortion * (portionsToRegenerate - 1)) - currentPortionAmount - totalBeforeIndex) * 100)) / 100
          if (amountFirstPortion < 0) {
            amountFirstPortion = amountFirstPortion * -1
          }
          if (amountPortion < 0) {
            amountPortion = amountPortion * -1
          }

          this.form.portions[index].amount = amountFirstPortion
          if ((index + 1) < portionsNumber) {
            for (let i = (index + 1); i < portionsNumber; i++) {
              this.form.portions[i].amount = amountPortion
            }
          }
        }
      },
      resetPortions () {
        this.isResetingPortions = true
        this.form.portions = []
        setTimeout(() => {
          this.isResetingPortions = false
        }, 100)
      },
      onSend () {
        this.isSubmitted = true
        const data = cloneDeep(this.form)
        data.amount = this.currencyBrToEn(data.amount)
        data.portions.forEach((item) => {
          item.amount = this.currencyBrToEn(item.amount)
        })
        restfulService.save('entry', null, null, data)
          .then(_ => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'entry.revenue.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
      }
    }
  }
</script>

<template>
    <div v-if="!isLoadingData">
        <ListHeader title="Lançamentos"/>
        <el-form ref="form"
                 :model="form"
                 label-position="top">
            <el-row :gutter="20">
                <el-col :span="simpleEntry ? 24 : 12">
                    <el-card class="el-card__save_form box-card">
                        <div slot="header">
                            <span>Dados do Lançamento</span>
                        </div>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form-item label="Classificação"
                                              class="is-required"
                                              v-bind:class="{ 'el-form-item--error': $v.form.classification_id.$error }">
                                    <el-select v-model="form.classification_id"
                                               filterable
                                               clearable
                                               placeholder="Selecione uma classificação"
                                               empty-text="Nenhum registro encontrado"
                                               class="el-select-full"
                                               @input="$v.form.classification_id.$touch()">
                                        <el-option
                                                v-for="item in dependencies.classifications"
                                                :key="item.id"
                                                :label="item.description"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <validation-message v-if="$v.form.classification_id.$error"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="Cliente"
                                              class="is-required"
                                              v-bind:class="{ 'el-form-item--error': $v.form.client_id.$error }">
                                    <el-select v-model="form.client_id"
                                               filterable
                                               clearable
                                               placeholder="Selecione um cliente"
                                               empty-text="Nenhum registro encontrado"
                                               class="el-select-full"
                                               @input="$v.form.client_id.$touch()">
                                        <el-option
                                                v-for="item in dependencies.clients"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <validation-message v-if="$v.form.client_id.$error"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <el-form-item label="Vencimento"
                                              class="is-required"
                                              v-bind:class="{ 'el-form-item--error': $v.form.due_date.$error }">
                                    <el-date-picker
                                            v-model="form.due_date"
                                            type="date"
                                            format="dd/MM/yyyy"
                                            value-format="yyyy-MM-dd"
                                            v-mask="'##/##/####'"
                                            placeholder="Vencimento"
                                            @input="$v.form.due_date.$touch()">
                                    </el-date-picker>
                                    <validation-message v-if="$v.form.due_date.$error"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="simpleEntry ? 16 : 10">
                                <el-form-item label="Valor"
                                              class="is-required"
                                              v-bind:class="{ 'el-form-item--error': $v.form.amount.$error }">
                                    <el-input v-model.lazy="form.amount"
                                              v-money="moneyMask"
                                              @input="$v.form.amount.$touch()"/>
                                    <validation-message v-if="$v.form.amount.$error"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6" v-if="!simpleEntry">
                                <el-form-item label="Parcelas"
                                              class="is-required">
                                    <el-select v-model="form.portions_number"
                                               filterable
                                               empty-text="Nenhum registro encontrado"
                                               class="el-select-full"
                                               :disabled="checkRecurrent">
                                        <el-option
                                                v-for="item in dependencies.portions"
                                                :key="item.id"
                                                :label="item.value"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" v-if="simpleEntry">
                            <el-col :span="8">
                                <el-form-item label="Gerar Saldo para o Cliente">
                                    <el-switch
                                            v-model="form.generate_balance"
                                            active-color="#13ce66"
                                            active-text="SIM"
                                            inactive-color="#ff4949"
                                            inactive-text="NÃO">
                                    </el-switch>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="Pago ?">
                                    <el-switch
                                            :disabled="form.generate_balance"
                                            v-model="form.is_paid"
                                            active-color="#13ce66"
                                            active-text="SIM"
                                            inactive-color="#ff4949"
                                            inactive-text="NÃO">
                                    </el-switch>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="Data de Pagamento"
                                              v-bind:class="{ 'el-form-item--error': $v.form.pay_date.$error, 'is-required': form.is_paid }">
                                    <el-date-picker
                                            v-model="form.pay_date"
                                            type="date"
                                            format="dd/MM/yyyy"
                                            value-format="yyyy-MM-dd"
                                            v-mask="'##/##/####'"
                                            placeholder="Data de Pagamento"
                                            :disabled="!form.is_paid"
                                            @input="$v.form.pay_date.$touch()">
                                    </el-date-picker>
                                    <validation-message v-if="$v.form.pay_date.$error"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" v-if="!simpleEntry">
                            <el-col :span="8">
                                <el-form-item label="Intervalo"
                                              class="is-required">
                                    <el-input-number v-model="form.interval"
                                                     :step="1"
                                                     :min="1">
                                    </el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10">
                                <el-form-item label="Tipo"
                                              class="is-required">
                                    <el-select v-model="form.interval_type"
                                               filterable
                                               empty-text="Nenhum registro encontrado"
                                               class="el-select-full">
                                        <el-option
                                                v-for="item in dependencies.interval_types"
                                                :key="item.id"
                                                :label="item.value"
                                                :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="Recorrente">
                                    <el-switch
                                            v-model="form.recurrent"
                                            active-color="#13ce66"
                                            active-text="SIM"
                                            inactive-color="#ff4949"
                                            inactive-text="NÃO">
                                    </el-switch>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item label="Descrição">
                            <el-input type="textarea"
                                      :rows="4"
                                      v-model="form.description"/>
                        </el-form-item>
                    </el-card>
                </el-col>
                <el-col :span="12" v-if="!simpleEntry">
                    <el-card class="el-card__save_form box-card">
                        <div slot="header">
                            <span>Parcelas</span>
                        </div>
                        <table class="custom-table-list" v-if="!isResetingPortions">
                            <thead>
                            <tr>
                                <th>Parcela</th>
                                <th>Descrição</th>
                                <th>Valor</th>
                                <th>Data de Vencimento</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in form.portions">
                                <td>
                                    <el-input v-model="item.portion" :readonly="true"/>
                                </td>
                                <td>
                                    <el-input v-model="item.description" :maxlength="200"/>
                                </td>
                                <td>
                                    <el-input v-model.lazy="item.amount"
                                              v-money="moneyMask"/>
                                </td>
                                <td>
                                    <el-date-picker
                                            v-model="item.due_date"
                                            type="date"
                                            format="dd/MM/yyyy"
                                            value-format="yyyy-MM-dd"
                                            v-mask="'##/##/####'"
                                            placeholder="Vencimento"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </el-card>
                </el-col>
            </el-row>

            <el-card class="el-card__save_form box-card el-card__save_form-submit">
                <form-save-button :event-click="onSend"
                                  :is-disabled="isSubmitted || $v.$invalid"
                                  :is-submitted="isSubmitted">
                </form-save-button>
                <form-cancel-button route-name="entry.revenue.index"/>
            </el-card>
        </el-form>
    </div>
</template>
