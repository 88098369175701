<script>
import ListMain from '../../../../components/list/Main.vue'
import ListHeader from '../../../../components/list/Header.vue'
import ListEditButton from '../../../../components/list/EditButton.vue'
import ListDeleteButton from '../../../../components/list/DeleteButton.vue'
import StatusLabel from './StatusLabel'

import bus from '@utils/bus'
import helpers from '@mixins/helpers'
import { mapGetters } from 'vuex'

import { clone } from 'lodash'

const defaultFilters = {
  name: '',
  status: 'all',
  email_verified: 'all',
  phone_verified: 'all',
}

export default {
  components: {ListMain, ListHeader, ListEditButton, ListDeleteButton, StatusLabel},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      filters: {...clone(defaultFilters)},
    }
  },
  mounted() {
    bus.$emit('list-init', {domain: 'admin_user'}, () => {
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore'])
  },
  methods: {
    onFilter() {
      const searchData = {}
      if (this.filters.name !== '') {
        searchData.name = {
          block: {
            or: [
              {name: {'like': '%' + this.filters.name + '%'}},
              {email: {'like': '%' + this.filters.name + '%'}},
              {phone: {'like': '%' + this.filters.name + '%'}}
            ]
          }
        }
      }
      if (this.filters.status !== 'all') {
        searchData.status = this.filters.status
      }
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      this.filters = {...clone(defaultFilters)}
      bus.$emit('list-filter-clean', this.filters)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    }
  },
  beforeMount() {
    this.listStore.listData = []
  }
}
</script>

<template>
  <div>

    <ListHeader title="Usuários do Painel" route-name="admin_user.store"/>

    <ListMain>

      <div slot="filters">
        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtrar Listagem</span>
            <div>
              <el-button type="primary" @click="onFilter">Pesquisar</el-button>
              <el-button type="default" @click="onFilterClean">Limpar</el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters" class="demo-form-inline">
            <el-form-item label="Nome/E-mail/Telefone">
              <el-input v-model="filters.name"
                        placeholder="Filtrar por Nome/E-mail/Telefone"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Status">
              <el-select v-model="filters.status"
                         placeholder="Filtrar por status"
                         @keydown.enter.native.prevent="onFilter">
                <el-option label="Todos" value="all"/>
                <el-option label="Ativo" value="active"/>
                <el-option label="Inativo" value="inactive"/>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>
      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th class="text-center">Status</th>
            <th>E-mail</th>
            <th>Telefone</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in listStore.listData">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td class="text-center"><status-label :status="item.status"/></td>
            <td>{{ item.email }}</td>
            <td>{{ item.phone }}</td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <ListEditButton route-name="admin_user.update" :item="item"/>
                <ListDeleteButton :item="item" domain="admin_user"/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
  </div>
</template>
