<script>
  import ListHeader from '../../../../components/list/Header.vue'
  import FormSaveButton from '../../../../components/form/SaveButton.vue'
  import FormCancelButton from '../../../../components/form/CancelButton.vue'

  import * as restfulService from '../../../../services/restfulService'
  import * as notifyService from '../../../../services/notifyService'

  import bus from '@utils/bus'

  import { required } from 'vuelidate/lib/validators'
  import { clone } from 'lodash'
  import DataService from '@/app/services/dataService';

  export default {
    components: { ListHeader, FormSaveButton, FormCancelButton },
    data () {
      return {
        isEdit: false,
        isSubmitted: false,
        isLoadingData: true,
        form: {
          name: '',
          sex: ''
        },
        dependencies: {
          player_class_sex: []
        }
      }
    },
    validations: {
      form: {
        name: { required },
        sex: { required },
      }
    },
    mounted () {
      this.getDependencies()
      const id = this.$route.params.id || null
      if (id) {
        this.isEdit = true
        restfulService.get('player_class', null, id, null, [])
          .then(response => {
            this.form = { ...response }
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
      } else {
        this.isLoadingData = false
        bus.$emit('hide-loader')
      }
    },
    methods: {
      getDependencies() {
        DataService.get([{domain: 'player_class_sex'}]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
        })
      },
      onSend () {
        this.isSubmitted = true
        const data = clone(this.form)
        restfulService.save('player_class', null, null, data)
          .then(() => {
            notifyService.success({ hideLoader: true })
            this.isSubmitted = false
            this.$router.push({ name: 'player_class.index' })
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
      }
    }
  }
</script>

<template>
    <div v-if="!isLoadingData">

        <ListHeader title="Categorias"/>

        <el-card class="el-card__save_form box-card">
            <div slot="header">
                <span>Dados das Categorias</span>
            </div>
            <el-form ref="form"
                     :model="form"
                     label-position="top"
                     v-on:submit.prevent="onSend">
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="Sexo"
                                class="is-required"
                                v-bind:class="{ 'el-form-item--error': $v.form.sex.$error }">
                    <el-select v-model="form.sex"
                               clearable
                               placeholder="Selecione um sexo"
                               empty-text="Nenhum registro encontrado"
                               class="el-select-full"
                               @input="$v.form.sex.$touch()">
                      <el-option v-for="item in dependencies.player_class_sex"
                                 :key="item.id"
                                 :label="item.name"
                                 :value="item.id">
                      </el-option>
                      <div class="el-form-item__error" v-if="$v.form.sex.$error">
                        Esse campo é obrigatório
                      </div>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="Nome"
                                class="is-required"
                                v-bind:class="{ 'el-form-item--error': $v.form.name.$error }">
                    <el-input v-model="form.name"
                              @input="$v.form.name.$touch()"/>
                    <div class="el-form-item__error" v-if="$v.form.name.$error">Esse campo é obrigatório</div>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item>
                <form-save-button :event-click="onSend"
                                  :is-disabled="isSubmitted || $v.$invalid"
                                  :is-submitted="isSubmitted"/>
                <form-cancel-button route-name="player_class.index"/>
              </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
