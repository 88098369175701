import axios from 'axios'
import interceptors from './interceptors'
import baseConfig from '../config'

/**
 * Cria instância do axios para as requisições ajax
 */
export const http = axios.create({
  baseURL: baseConfig.fullAPIPath,
  headers: {'content-type': 'application/json'},
  timeout: 50000
})

export function setToken (token) {
  http.defaults.headers.common.Authorization = `Bearer ${token}`
}

export function setSession (session) {
  http.defaults.headers.common.Session = session
}

export default function install (Vue, {router}) {
  interceptors(http, router)
}
