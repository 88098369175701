<script>
export default {
  name: 'notification-user-type',
  props: ['ownerType'],
  computed: {
    ownerTypeLabel() {
      if (this.ownerType === 'Domain\\Admin\\System\\User\\User') {
        return 'App'
      } else if (this.ownerType === 'Domain\\Admin\\System\\AdminUser\\AdminUser') {
        return 'Painel'
      } else {
        return 'Tenant'
      }
    },
    ownerTypeTagType() {
      if (this.ownerType === 'Domain\\Admin\\System\\User\\User') {
        return 'warning'
      } else if (this.ownerType === 'Domain\\Admin\\System\\AdminUser\\AdminUser') {
        return 'default'
      } else {
        return 'success'
      }
    }
  }
}
</script>

<template>
  <div>
    <el-tag size="mini" :type="ownerTypeTagType">{{ownerTypeLabel}}</el-tag>
  </div>
</template>
