<script>
import ListHeader from '../../../../components/list/Header.vue'
import FormSaveButton from '../../../../components/form/SaveButton.vue'
import FormCancelButton from '../../../../components/form/CancelButton.vue'
import ValidationMessage from '../../../../components/form/ValidationMessage.vue'
import FormContacts from '../../../../components/form/Contacts'
import FormClientPaymentAccounts from '../../../../components/form/ClientPaymentAccounts.vue'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import DataService from '../../../../services/dataService'
import {get as getCep} from '../../../../services/cepService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'

import {required, requiredIf} from 'vuelidate/lib/validators'
import {cloneDeep, isObject, merge, isEmpty} from 'lodash'
import AuthButton from "./AuthButton.vue";

const defaultModules = {
  tournament: false,
  basic: false,
  professional: false,
  totem: false,
  faceRecon: false,
  stoneConnect: false,
}

const defaultNfseCityConfig = {
  endereco_obrigatorio_nfse: false,
  cpf_cnpj_obrigatorio_nfse: false,
  codigo_cnae_obrigatorio_nfse: false,
  item_lista_servico_obrigatorio_nfse: false,
  codigo_tributario_municipio_obrigatorio_nfse: false,
}

export default {
  components: {
    AuthButton,
    ListHeader,
    FormSaveButton,
    FormCancelButton,
    ValidationMessage,
    FormContacts,
    FormClientPaymentAccounts
  },
  mixins: [helpers],
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      isSearchingCity: false,
      form: {
        generate_client_panel: false,
        city_id: '',
        database_host: '',
        database_user: '',
        database_password: '',
        database_port: '',
        type: 'legal',
        name: '',
        nickname: '',
        legal_name: '',
        club_name: '',
        birth_date: '',
        cpf_cnpj: '',
        state_registration: '',
        notes: '',
        clubs: [],
        gateway_login: '',
        gateway_token: '',
        pagarme_recipient_id: '',
        generate_nfse: false,
        email: '',
        phone: '',
        payment_method: 'pix',
        modules: {...defaultModules},
        address: {
          cep: '',
          street: '',
          number: '',
          neighborhood: '',
          complement: '',
          codigo_ibge: '',
          city_id: '',
          city: {
            id: '',
            state_id: '',
            name: '',
            name_with_state: '',
            state: {
              id: '',
              name: '',
              initials: '',
            }
          },
        },
        payment_accounts: [],
        settings: {
          tenant_nf: {
            certificate: {
              exists: false,
              expire_date: '',
              file: '',
              base64: '',
              password: ''
            },
            focus_nfe_token_homologacao: '',
            focus_nfe_token_producao: '',
            nfce: {
              enabled: false,
              staging: true,
              inscricao_estadual: '',
              regime_tributario: '',
            },
            nfse: {
              enabled: false,
              staging: true,
              inscricao_municipal: '',
              regime_especial_tributacao: '',
              login: '',
              password: '',
              codigo_cnae: '',
              item_lista_servico: '',
              codigo_tributario_municipio: '',
              iss_retido: false,
              aliquota: 0,
              city_config: {...defaultNfseCityConfig}
            }
          },
          tenant_stone_payment: {
            active: false,
            requested: false,
            stoneCode: '',
            sk_secret: '',
            enabled: false,
            devices: []
          },
          tenant_pos_controle: {
            active: false,
            requested: false,
            auth: {
              username: '',
              password: '',
            },
            enabled: false,
            devices: []
          }
        }
      },
      showGatewayToken: false,
      selectedClubs: [],
      dependencies: {
        clubs: [],
        cities: [],
        banks: [],
        nf_regime_tributario: [],
        nf_regime_especial_tributacao: [],
      }
    }
  },
  validations: {
    form: {
      city_id: {required},
      name: {required},
      club_name: {
        required: requiredIf(function (obj) {
          return obj.generate_client_panel
        })
      }
    }
  },
  computed: {
    gestaoPath() {
      return `${process.env.VUE_APP_GESTAO_APP_URL}/auth/login/`
    },
    clientUserAdmin() {
      return Array.isArray(this.form.clubs) && this.form.clubs.length && Array.isArray(this.form.clubs[0].users) && this.form.clubs[0].users.length
          ? this.form.clubs[0].users.find(item => item.user.roles.some(role => role.key === 'administrador'))?.user || {}
          : {}
    },
    usersList() {
      let users = []
      this.form.clubs.map(club => {
        club.users.map(clientUser => {
          if (!users.some(user => user.id === clientUser.user.id)) {
            users.push(clientUser.user)
          }
        })
      })
      return users
    }
  },
  watch: {
    async 'form.address.cep'(value, oldValue) {
      const cep = this.onlyNumbers(value)
      const cepOldValue = this.onlyNumbers(oldValue)
      if (value && cepOldValue.length === 7) {
        if (cep.length === 8) {
          const cepFound = await getCep(cep)
          if (cepFound) {
            this.$refs.formAddressNumber.focus()
            this.form.address.street = cepFound.logradouro
            this.form.address.neighborhood = cepFound.bairro
            this.form.address.complement = cepFound.complemento
            this.form.address.codigo_ibge = cepFound.ibge
          }
        } else {
          this.form.address.street = ''
          this.form.address.neighborhood = ''
          this.form.address.number = ''
          this.form.address.complement = ''
          this.form.address.codigo_ibge = ''
        }
      }
    },
    'form.modules.stoneConnect': function (value) {
      this.form.settings.tenant_stone_payment.active = value
    },
    'form.settings.tenant_pos_controle.active': function (value) {
      this.form.settings.tenant_pos_controle.enabled = value
    }
  },
  mounted() {
    this.getDependencies()
    const id = this.$route.params.id || null
    if (id) {
      this.isEdit = true
      this.getUser(id)
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
  },
  methods: {
    getDependencies() {
      DataService.get([{domain: 'club'}, {domain: 'bank'}, {domain: 'nf_regime_tributario'}, {domain: 'nf_regime_especial_tributacao'}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    getUser(id) {
      restfulService.get('client', null, id, null, ['contacts', 'city', 'clubs.club', 'address.city.state', 'clubs.users.user', 'payment_accounts.bank', 'settings'])
          .then(response => {
            this.selectedClubs = response.clubs.map((item) => {
              return item.club_id
            })
            if (response.modules === null || (response.modules !== null && !isObject(response.modules) && response.modules.length === 0)) {
              response.modules = {...defaultModules}
            }
            if (response.address === null) {
              response.address = {...this.form.address}
            }
            if (response.settings === null) {
              response.settings = {...cloneDeep(this.form.settings)}
            } else if (Array.isArray(response.settings)) {
              response.settings = response.settings.reduce((total, item) => {
                total[item.type] = item.data
                return total
              }, {})
              if (response.settings.tenant_payment === null) {
                response.settings.tenant_payment = {...cloneDeep(this.form.settings.tenant_payment)}
              } else {
                response.settings.tenant_payment = {...merge(this.form.settings.tenant_payment, response.settings.tenant_payment)}
              }
              if (response.settings.tenant_nf === null) {
                response.settings.tenant_nf = {...cloneDeep(this.form.settings.tenant_nf)}
              } else {
                response.settings.tenant_nf = {...merge(this.form.settings.tenant_nf, response.settings.tenant_nf)}
              }
              if (response.settings.tenant_nf.nfse
                  && response.settings.tenant_nf.nfse.city_config
                  && isObject(response.settings.tenant_nf.nfse.city_config)
                  && isEmpty(response.settings.tenant_nf.nfse.city_config)) {
                response.settings.tenant_nf.nfse.city_config = cloneDeep(defaultNfseCityConfig)
              }
              if (response.settings.tenant_stone_payment === null) {
                response.settings.tenant_stone_payment = {...cloneDeep(this.form.settings.tenant_stone_payment)}
              } else {
                response.settings.tenant_stone_payment = {...merge(this.form.settings.tenant_stone_payment, response.settings.tenant_stone_payment)}
              }
              if (response.settings.tenant_pos_controle === null) {
                response.settings.tenant_pos_controle = {...cloneDeep(this.form.settings.tenant_pos_controle)}
              } else {
                response.settings.tenant_pos_controle = {...merge(this.form.settings.tenant_pos_controle, response.settings.tenant_pos_controle)}
              }
            }
            response.type = response.cpf_cnpj && this.onlyNumbers(response.cpf_cnpj).length === 11 ? 'natural' : 'legal'
            this.form = {...merge(this.form, response)}
            if (this.form.city) {
              this.form.address.city_id = response.city_id
              this.form.address.city = {...response.city}
              this.dependencies.cities.push(this.form.city)
            }
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    },
    loginUrl(login) {
      return `${this.gestaoPath}?login=${login}`
    },
    onSearchCity(query) {
      return new Promise((resolve, reject) => {
        this.dependencies.cities.splice(0)
        if (query !== '') {
          this.isSearchingCity = true
          let data = {query}
          restfulService.post('city', 'autocomplete', null, data)
              .then((response) => {
                this.dependencies.cities.splice(0)
                this.dependencies.cities = [...this.dependencies.cities, ...response]
                this.isSearchingCity = false
                resolve()
              })
              .catch(e => {
                console.log(e)
                reject()
              })
        }
      })
    },
    onSend() {
      this.isSubmitted = true
      bus.$emit('show-loader')
      let data = {...cloneDeep(this.form)}
      data.cpf_cnpj = this.onlyNumbers(data.cpf_cnpj)
      data.clubs = this.selectedClubs
      data.contacts = {
        email: null,
        phone: null,
      }
      if (data.address.cep) {
        data.address.cep = this.onlyNumbers(data.address.cep)
      }
      if (!this.isEdit && data.email) {
        const {email} = data
        data.contacts.email = email.trim().toLowerCase();
      }
      if (!this.isEdit && data.phone) {
        const {phone} = data
        data.contacts.phone = this.onlyNumbers(phone);
      }
      restfulService.save('client', null, null, data)
          .then((response) => {
            notifyService.success()
            this.isSubmitted = false
            this.getUser(response.tenant_id)
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    },
    copyText(ref) {
      const codeToCopy = this.$refs[ref]
      navigator.clipboard.writeText(codeToCopy.value)
      this.$notify({
        title: 'Texto copiado!',
        type: 'success',
        duration: 1000,
        position: 'top-right'
      })
    }
  }
}
</script>

<template>
  <div v-if="!isLoadingData">
    <ListHeader title="Clientes"/>
    <el-form ref="form"
             :model="form"
             label-position="top">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>Dados do Cliente</span>
            </div>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="Tipo de Pessoa"
                              class="is-required"
                              v-bind:class="{ 'el-form-item--error': $v.form.name.$error }">
                  <el-radio-group v-model="form.type" size="small">
                    <el-radio-button label="natural">Física</el-radio-button>
                    <el-radio-button label="legal">Jurídica</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item :label="form.type === 'natural' ? 'CPF' : 'CNPJ'">
                  <el-input v-model="form.cpf_cnpj"
                            v-mask="form.type === 'natural' ? '###.###.###-##' : '##.###.###/####-##'"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="16">
                <el-form-item :label="form.type === 'natural' ? 'Nome' : 'Nome Fantasia'"
                              class="is-required"
                              v-bind:class="{ 'el-form-item--error': $v.form.name.$error }">
                  <el-input v-model="form.name"
                            @input="$v.form.name.$touch()"/>
                  <validation-message v-if="$v.form.name.$error"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="form.type === 'natural' ? 'Data Nascimento' : 'Data de Abertura'">
                  <el-date-picker
                      v-model="form.birth_date"
                      type="date"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                      v-mask="'##/##/####'"
                      :placeholder="form.type === 'natural' ? 'Data Nascimento' : 'Data de Abertura'">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12" v-if="form.type === 'legal'">
                <el-form-item label="Razão Social">
                  <el-input v-model="form.legal_name"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Cidade"
                              class="is-required"
                              v-bind:class="{ 'el-form-item--error': $v.form.city_id.$error }">
                  <el-select v-model="form.city_id"
                             clearable
                             filterable
                             remote
                             :remote-method="onSearchCity"
                             :loading="isSearchingCity"
                             placeholder="Selecione uma cidade"
                             empty-text="Nenhum registro encontrado"
                             class="el-select-full"
                             @input="$v.form.city_id.$touch()">
                    <el-option v-for="item in dependencies.cities"
                               :key="item.id"
                               :label="item.name_with_state"
                               :value="item.id">
                    </el-option>
                  </el-select>
                  <div class="el-form-item__error" v-if="$v.form.city_id.$error">
                    Esse campo é obrigatório
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8" v-if="isEdit">
                <el-form-item label="Status">
                  <el-select v-model="form.status">
                    <el-option label="Ativo" value="active"/>
                    <el-option label="Inativo" value="inactive"/>
                    <el-option label="Bloqueado" value="blocked"/>
                    <el-option label="Período de Testes" value="trial_time"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Forma de Pagamento Padrão">
                  <el-select v-model="form.payment_method">
                    <el-option label="PIX" value="pix"/>
                    <el-option label="Boleto" value="boleto"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Gerar NFSe">
                  <el-switch v-model="form.generate_nfse"
                             active-color="#13ce66"
                             active-text="SIM"
                             inactive-color="#ff4949"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="Observações">
                  <el-input type="textarea"
                            :rows="2"
                            v-model="form.notes"/>
                </el-form-item>
              </el-col>
            </el-row>
            <template v-if="!isEdit">
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item label="Gerar Acesso ao Gripo">
                    <el-switch v-model="form.generate_client_panel"
                               active-color="#13ce66"
                               active-text="SIM"
                               inactive-color="#ff4949"
                               inactive-text="NÃO">
                    </el-switch>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item label="Email">
                    <el-input v-model="form.email"/>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item label="Telefone">
                    <el-input v-model="form.phone"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <template v-if="form.generate_client_panel">
                <el-form-item label="Nome do Clube"
                              class="is-required"
                              v-if="!isEdit"
                              v-bind:class="{ 'el-form-item--error': $v.form.club_name.$error }">
                  <el-input v-model="form.club_name"
                            @input="$v.form.club_name.$touch()"/>
                  <validation-message v-if="$v.form.club_name.$error"/>
                </el-form-item>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Host">
                      <el-input v-model="form.database_host"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Nome da base">
                      <el-input v-model="form.database_name"
                                :readonly="true"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Usuário da base">
                      <el-input v-model="form.database_user"
                                type="password"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Senha da base">
                      <el-input v-model="form.database_password"
                                type="password"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="Porta Padrão">
                      <el-input v-model="form.database_port"
                                type="password"/>
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
            </template>
            <el-row :gutter="20" v-if="form.database_name">
              <el-col :span="10">
                <el-form-item label="Login">
                  <el-input :value="clientUserAdmin.email || clientUserAdmin.login"
                            ref="loginRef"
                            @focus="copyText('loginRef')"
                            suffix-icon="fas fa-copy"
                            readonly>
                  </el-input>
                </el-form-item>
                <auth-button :tenant-id="form.id" :tenant-user-id="clientUserAdmin?.id"/>
              </el-col>
              <el-col :span="14">
                <el-form-item label="Database Name">
                  <el-input :value="form.database_name"
                            ref="databaseNameRef"
                            @focus="copyText('databaseNameRef')"
                            suffix-icon="fas fa-copy"
                            readonly>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="el-card__save_form box-card contact-box"
                   style="margin-top: 15px;">
            <div slot="header">
              <span>Módulos/Integrações</span>
            </div>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="Torneios">
                  <el-switch v-model="form.modules.tournament"
                             active-color="#13ce66"
                             active-text="SIM"
                             inactive-color="#ff4949"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Básico">
                  <el-switch v-model="form.modules.basic"
                             active-color="#13ce66"
                             active-text="SIM"
                             inactive-color="#ff4949"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Profissional">
                  <el-switch v-model="form.modules.professional"
                             active-color="#13ce66"
                             active-text="SIM"
                             inactive-color="#ff4949"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="Stone Connect">
                  <el-switch v-model="form.modules.stoneConnect"
                             active-color="#13ce66"
                             active-text="SIM"
                             inactive-color="#ff4949"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Totem">
                  <el-switch v-model="form.modules.totem"
                             active-color="#13ce66"
                             active-text="SIM"
                             inactive-color="#ff4949"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Reconhecimento Facial">
                  <el-switch v-model="form.modules.faceRecon"
                             active-color="#13ce66"
                             active-text="SIM"
                             inactive-color="#ff4949"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-if="form.modules.stoneConnect">
              <el-col :span="8">
                <el-form-item label="Ativar Integração Stone Connect">
                  <el-switch v-model="form.settings.tenant_stone_payment.active"
                             :disabled="!form.settings.tenant_stone_payment.stoneCode"
                             :active-value="true"
                             :inactive-value="false"
                             active-text="SIM"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Código Stone" size="small">
                  <el-input v-model="form.settings.tenant_stone_payment.stoneCode"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Secret Key" size="small">
                  <el-input v-model="form.settings.tenant_stone_payment.sk_secret"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="Ativar Integração POS Controle">
                  <el-switch v-model="form.settings.tenant_pos_controle.active"
                             :active-value="true"
                             :inactive-value="false"
                             active-text="SIM"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Login" size="small">
                  <el-input v-model="form.settings.tenant_pos_controle.auth.username"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Senha" size="small">
                  <el-input v-model="form.settings.tenant_pos_controle.auth.password"/>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="el-card__save_form box-card mt-4" v-if="form.settings.tenant_nf.nfse.enabled">
            <div slot="header">
              <span>Configurações NFSe</span>
            </div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Endereço Obrigatório">
                  <el-switch v-model="form.settings.tenant_nf.nfse.city_config.endereco_obrigatorio_nfse"
                             active-color="#13ce66"
                             active-text="SIM"
                             inactive-color="#ff4949"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="CPF/CNPJ Obrigatório">
                  <el-switch v-model="form.settings.tenant_nf.nfse.city_config.cpf_cnpj_obrigatorio_nfse"
                             active-color="#13ce66"
                             active-text="SIM"
                             inactive-color="#ff4949"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Código CNAE Obrigatório">
                  <el-switch v-model="form.settings.tenant_nf.nfse.city_config.codigo_cnae_obrigatorio_nfse"
                             active-color="#13ce66"
                             active-text="SIM"
                             inactive-color="#ff4949"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Código Tributário Obrigatório">
                  <el-switch
                      v-model="form.settings.tenant_nf.nfse.city_config.codigo_tributario_municipio_obrigatorio_nfse"
                      active-color="#13ce66"
                      active-text="SIM"
                      inactive-color="#ff4949"
                      inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Item Lista Serviço Obrigatório">
                  <el-switch v-model="form.settings.tenant_nf.nfse.city_config.item_lista_servico_obrigatorio_nfse"
                             active-color="#13ce66"
                             active-text="SIM"
                             inactive-color="#ff4949"
                             inactive-text="NÃO">
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="12"></el-col>
            </el-row>
          </el-card>
          <el-card class="el-card__save_form box-card mt-4">
            <div slot="header">
              <span>Usuários</span>
            </div>
            <table class="custom-table-list">
              <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Login</th>
                <th>Perfis</th>
                <th>Abrir Login</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="user in usersList" :key="user.id">
                <td>{{ user.id }}</td>
                <td>{{ user.name }}</td>
                <td>
                  <div v-if="user.login">Login: {{ user.login }}</div>
                  <div v-if="user.email">E-mail: {{ user.email }}</div>
                  <div v-if="user.phone">Telefone: {{ user.phone }}</div>
                </td>
                <td>{{ user.roles.map(item => item.name).join(', ') }}</td>
                <td>
                  <input type="hidden"
                         :value="user.login || user.email">
                  <auth-button :tenant-id="form.id" :tenant-user-id="user.id" label="Entrar"/>
                </td>
              </tr>
              </tbody>
            </table>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="el-card__save_form box-card">
            <div slot="header">
              <span>Endereço</span>
            </div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="CEP">
                  <el-input v-model="form.address.cep"
                            v-mask="'#####-###'"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Endereço">
                  <el-input v-model="form.address.street"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Número">
                  <el-input v-model="form.address.number" ref="formAddressNumber"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Bairro">
                  <el-input v-model="form.address.neighborhood"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Complemento">
                  <el-input v-model="form.address.complement"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Código IBGE">
                  <el-input v-model="form.address.codigo_ibge"/>
                </el-form-item>
              </el-col>
            </el-row>
          </el-card>

          <div :class="['mt-4', {'disabled': !isEdit}]">
            <form-client-payment-accounts
                :list.sync="form.payment_accounts"
                :ownerId="form.id"
                :cpf-cnpj="form.cpf_cnpj"
                :dependencies="dependencies"
            />

            <form-contacts :list="form.contacts"
                           :ownerId="form.id"
                           ownerType="client"
                           style="margin-top: 15px;margin-bottom: 15px;"/>
            <el-card class="el-card__save_form box-card contact-box">
              <div slot="header">
                <span>Clubes</span>
              </div>
              <el-transfer v-model="selectedClubs"
                           :data="dependencies.clubs"
                           :titles="['Disponíveis', 'Selecionados']"
                           :props="{
                                          key: 'id',
                                          label: 'name'
                                    }">
              </el-transfer>
            </el-card>
          </div>
        </el-col>
      </el-row>
      <el-card class="el-card__btn_form">
        <el-form-item>
          <form-save-button :event-click="onSend"
                            :is-disabled="isSubmitted || $v.form.$invalid"
                            :is-submitted="isSubmitted">
          </form-save-button>
          <form-cancel-button route-name="client.index"/>
        </el-form-item>
      </el-card>
    </el-form>
  </div>
</template>
