import store from '../../vuex'
import * as restfulService from './restfulService'

function _login (data) {
  return new Promise((resolve, reject) => {
    restfulService.post('auth', 'login', null, data)
      .then(response => {
        store.dispatch('login', response).then(response => {
          resolve(response)
        })
      })
      .catch(e => {
        console.log(e)
        reject()
      })
  })
}

function _logout () {
  return new Promise((resolve) => {
    store.dispatch('logout').then(() => resolve())
  })
}

export const login = _login
export const logout = _logout
