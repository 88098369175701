<script>
import ListMain from '../../../../components/list/Main.vue'
import ListHeader from '../../../../components/list/Header.vue'
import ListShowButton from '../../../../components/list/ShowButton.vue'
import ValidationMessage from '../../../../components/form/ValidationMessage';
import EntryStatus from './labels/StatusLabel.vue'
import EntryStatusCircle from './labels/StatusCircle.vue'

import DataService from '../../../../services/dataService'
import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import Swal from 'sweetalert2'
import {clone} from 'lodash'
import moment from 'moment'
import {required} from 'vuelidate/lib/validators'

const defaultFilters = {
  type: 'revenue',
  client: '',
  status: 'created',
  dates: [helpers.methods.dateFirstDayOfMonth(true), helpers.methods.dateLastDayOfMonth(true)],
  show_opened_entries: false,
  classification_ids: [],
  has_nfse: 'all',
  due_date: true
}

export default {
  components: {ListMain, ListHeader, ListShowButton, EntryStatus, EntryStatusCircle, ValidationMessage},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      isSubmitted: false,
      filters: {...clone(defaultFilters)},
      selectedList: [],
      showModalSettledSelected: false,
      showModalAmountBankSlip: false,
      paymentMethodId: '',
      showModalSettledPortion: false,
      showPortionPayDateDatePicker: false,
      showAmountBankSlip: false,
      showModalPdf: false,
      pdfBase64: '',
      amountBankSlip: 0,
      portion: {
        id: '',
        payment_method_id: '',
        pay_date: '',
        amount_paid: '',
        description: ''
      },
      moneyMask: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      dependencies: {
        clients: [],
        status: [],
        payment_methods: [],
        classifications: []
      },
      cards: {
        loading: true,
        waitingFunds: 0,
        balanceAvailable: 0,
      }
    }
  },
  computed: {
    ...mapGetters(['listStore']),
    portionPayDateFormatted: {
      get() {
        return this.portion.pay_date ? this.dateFormat(this.portion.pay_date, 'br') : ''
      },
      set(value) {
        console.log(value)
      }
    },
    totalAmountCreated: function () {
      return this.currencyEnToBr(this.listStore.listData.filter((item) => item.status === 'created').reduce((a, b) => a + b.amount, 0))
    },
    totalAmountSettled: function () {
      return this.currencyEnToBr(this.listStore.listData.filter((item) => item.status === 'settled').reduce((a, b) => a + b.amount_paid, 0))
    },
    isAllChecked() {
      return this.selectedList.length === this.listStore.listData.length
    },
    hasBalance() {
      return this.cards.balanceAvailable > 0
    },
    hasWaitingFunds() {
      return this.cards.waitingFunds > 0
    }
  },
  validations: {
    portion: {
      payment_method_id: {required},
      pay_date: {required},
      amount_paid: {required}
    },
    amountBankSlip: {required}
  },
  async mounted() {
    this.getDependencies()
    this.getBalance()
    const searchData = await this.prepareSearchData()
    bus.$emit('list-init', {
      domain: 'entry_search',
      searchRoute: false,
      processResponse: this.processListResponse,
      data: searchData
    }, () => {
      this.isLoadingData = false
    })
  },
  methods: {
    processListResponse(list) {
      list.forEach((item) => {
        item.received_late = item.pay_date > item.due_date && item.status === 'settled'
        item.overdue = moment(item.due_date, 'YYYY-MM-DD') < moment() && item.status !== 'settled'
        if (item.amount_paid && item.amount_paid < item.amount) {
          item.outstanding = true
        }
        item.amount_br = this.currencyEnToBr(item.amount)
        if (item.amount_paid) {
          item.amount_paid_br = this.currencyEnToBr(item.amount)
        }
        item.checked = false
      })
      return list
    },
    getDependencies() {
      DataService.get([{domain: 'client'}, {domain: 'status'}, {domain: 'payment_method'}, {domain: 'classification_revenue'}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    prepareSearchData() {
      const searchData = {type: defaultFilters.type}
      searchData.due_date = this.filters.due_date
      if (this.filters.client) {
        searchData.client = this.filters.client
      }
      if (this.filters.classification_ids.length > 0) {
        searchData.classification_ids = this.filters.classification_ids
      }
      searchData.status = this.filters.status ? this.filters.status : null
      if (searchData.status === 'created') {
        searchData.due_date = true
      }
      if (this.filters.dates && this.filters.dates.length > 0) {
        let startDate = this.dateFormat(this.filters.dates[0], 'en')
        let endDate = this.dateFormat(this.filters.dates[1], 'en')
        if (startDate.length > 0 && endDate.length > 0) {
          searchData.start_date = startDate
          searchData.end_date = endDate
        }
      }
      searchData.show_opened_entries = this.filters.show_opened_entries
      if (searchData.status === 'settled') {
        searchData.show_opened_entries = false
      }
      if (this.filters.has_nfse !== 'all') {
        searchData.has_nfse = this.filters.has_nfse === 'yes'
      }
      return searchData
    },
    onFilter() {
      return new Promise(resolve => {
        const data = this.prepareSearchData()
        bus.$emit('list-filter', data, () => {
          this.resetSelectedList()
          resolve()
        })
      })
    },
    onFilterClean() {
      this.filters = clone(defaultFilters)
      const defaultSearchData = this.prepareSearchData()
      bus.$emit('list-filter-clean', this.filters, defaultSearchData)
    },
    onDestroy(item) {
      Swal.fire({
        title: 'Deseja realmente cancelar o lançamento selecionado?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Cancelar'
      })
          .then((result) => {
            if (result.value) {
              bus.$emit('show-loader')
              const data = {entry_portion_id: item.id, entry_id: item.entry_id, date: item.due_date}
              restfulService.post('entry_portion', 'destroy', null, data)
                  .then(() => {
                    notifyService.success({message: 'Lançamento cancelado com sucesso.'})
                    this.onFilter()
                  })
                  .catch(e => {
                    console.log(e)
                    bus.$emit('hide-loader')
                  })
            }
          })
    },
    checkItem(entry) {
      if (this.selectedList.some(item => item === entry.id)) {
        this.selectedList = this.selectedList.filter(item => item !== entry.id)
        entry.checked = false
      } else {
        this.selectedList.push(entry.id)
        entry.checked = true
      }
    },
    checkAll() {
      if (this.isAllChecked) {
        this.resetSelectedList()
      } else {
        this.selectedList = [...this.listStore.listData.map(item => item.id)]
        this.listStore.listData.forEach(entry => {
          entry.checked = true
        })
      }
    },
    selectedAction(command) {
      if (command === 'Quitar') {
        this.handleModalSettledSelected()
      }
    },
    resetSelectedList() {
      this.selectedList.splice(0)
      this.listStore.listData.forEach(entry => {
        entry.checked = false
      })
    },
    handleModalSettledSelected() {
      this.showModalSettledSelected = !this.showModalSettledSelected
    },
    handleModalSettledPortion() {
      this.showModalSettledPortion = !this.showModalSettledPortion
    },
    handleModalAmountBankSlip() {
      this.showModalAmountBankSlip = !this.showModalAmountBankSlip
    },
    async settledSelected() {
      Swal.fire({
        title: 'Deseja realmente quitar os lançamentos selecionados?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Cancelar'
      })
          .then((result) => {
            if (result.value) {
              bus.$emit('show-loader')
              const data = {entries: this.selectedList, payment_method_id: this.paymentMethodId}
              restfulService.post('entry_portion', 'finish-multiple', null, data)
                  .then(() => {
                    notifyService.success({message: 'Lançamentos quitados com sucesso.'})
                    this.onFilter().then(() => {
                      setTimeout(() => {
                        this.handleModalSettledSelected()
                      }, 300)
                    })
                  })
                  .catch(e => {
                    console.log(e)
                    bus.$emit('hide-loader')
                  })
            }
          })
    },
    openSettlePortion(portion) {
      let portionData = clone(portion)
      portionData.amount_paid = this.currencyEnToBr(portionData.amount)
      portionData.pay_date = this.dateNow('en')
      this.portion = {...portionData}
      this.handleModalSettledPortion()
    },
    settledPortion() {
      this.isSubmitted = true
      const data = clone(this.portion)
      data.amount_paid = this.currencyBrToEn(data.amount_paid)
      if (data.id) {
        restfulService.put('entry_portion', 'finish', data.id, data)
            .then(() => {
              this.isSubmitted = false
              this.onFilter().then(() => {
                setTimeout(() => {
                  this.handleModalSettledPortion()
                }, 300)
              })
            })
            .catch(e => {
              this.isSubmitted = false
              console.log(e)
            })
      } else {
        restfulService.post('entry_portion', 'finish-temp', null, data)
            .then(() => {
              this.isSubmitted = false
              this.onFilter().then(() => {
                setTimeout(() => {
                  this.handleModalSettledPortion()
                }, 300)
              })
            })
            .catch(e => {
              this.isSubmitted = false
              console.log(e)
            })
      }
    },
    modalGenerateBankSlip(item) {
      this.showAmountBankSlip = false
      this.portion = {...item}
      this.amountBankSlip = this.currencyEnToBr(item.amount)
      setTimeout(() => {
        this.handleModalAmountBankSlip()
        this.showAmountBankSlip = true
      }, 300)
    },
    generateBankSlip(item) {
      bus.$emit('show-loader')
      const data = {entry_id: item.entry_id, due_date: item.due_date, entry_portion_id: item.id}
      this.amountBankSlip = this.currencyBrToEn(this.amountBankSlip)
      if (this.amountBankSlip !== item.amount) {
        data.amount = this.amountBankSlip
      }
      restfulService.post('entry_portion', 'bank-slip', null, data)
          .then(response => {
            this.handleModalAmountBankSlip()
            notifyService.success({message: 'Boleto gerado com sucesso.'})
            this.showBankSlipPdf(response.base64)
            this.onFilter()
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    showTransaction(item) {
      bus.$emit('show-loader')
      restfulService.post('entry_portion', 'show-transaction', item.id, {
        entry_portion_id: item.id,
        entry_id: item.entry_id,
        due_date: item.due_date,
      })
          .then(response => {
            notifyService.success({message: 'Pagamento gerado com sucesso.'})
            this.onFilter()
          })
          .catch(e => {
            console.log(e)
            bus.$emit('hide-loader')
          })
    },
    cancelRecurrent(item) {
      Swal.fire({
        title: 'Deseja realmente cancelar a recorrência desse lançamento?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Cancelar'
      })
          .then((result) => {
            if (result.value) {
              bus.$emit('show-loader')
              restfulService.put('entry', 'cancel-recurrent', item.entry_id)
                  .then(() => {
                    notifyService.success({message: 'Recorrência cancelada'})
                    this.onFilter()
                  })
                  .catch(e => {
                    console.log(e)
                    bus.$emit('hide-loader')
                  })
            }
          })
    },
    showBankSlipPdf(base64) {
      this.pdfBase64 = base64
      this.showModalPdf = true
    },
    showInvoice(item) {
      window.open(item.entry.invoice.pdf_file, '_blank')
    },
    generateInvoice(item) {
      Swal.fire({
        title: 'Deseja realmente gerar uma NFSe para esse lançamento??',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Cancelar'
      })
          .then((result) => {
            if (result.value) {
              bus.$emit('show-loader')
              restfulService.post('entry_portion', 'generate-invoice', item.id)
                  .then(() => {
                    notifyService.success({message: 'NFSe adiciona a fila para ser gerada'})
                    this.onFilter()
                  })
                  .catch(e => {
                    console.log(e)
                    bus.$emit('hide-loader')
                  })
            }
          })
    },
    async getBalance() {
      restfulService.post('transaction', 'balance')
          .then(async (response) => {
            this.cards.balanceAvailable = response.balance
            this.cards.waitingFunds = response.waiting_funds
            this.cards.loading = false
          })
          .catch(e => {
            this.cards.loading = false
            console.log(e)
          });
    },
    createTransfer() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      restfulService.post('transaction', 'create-transfer')
          .then(response => {
            this.isSubmitted = false
            notifyService.success({title: response.message})
            bus.$emit('hide-loader')
          })
          .catch(e => {
            this.isSubmitted = false
            bus.$emit('hide-loader')
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div>

    <ListHeader title="Contas a Receber"
                route-name="entry.revenue.store"
                store-title="Criar Lançamento">
      <router-link :to="{ name: 'entry.revenue.store', params: {simpleEntry: true} }" class="el-button">
        <i class="fas fa-plus-circle"></i>
        <span>Criar Lançamento Simples</span>
      </router-link>
    </ListHeader>

    <el-row :gutter="24" class="mb-4">
      <el-col :span="6">
        <div class="list-card" v-loading="cards.loading">
          <div class="text-center">
            <strong>Taxa por Pagamento:</strong>
            <div class="value">
              <div>PIX: 1.19%</div>
              <div>Boleto: R$ 2,80</div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="list-card" v-loading="cards.loading">
          <div class="text-center">
            <strong>Taxa por Saque:</strong>
            <div class="value">R$ 3,67</div>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="list-card" v-loading="cards.loading">
          <div class="text-center">
            <strong>Saldo Atual:</strong>
            <div class="value">R$ {{ cards.balanceAvailable | currencyEnToBr }}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="6" v-if="!hasBalance && hasWaitingFunds">
        <div class="list-card" v-loading="cards.loading">
          <div class="text-center">
            <strong>Saque Solicitado:</strong>
            <div class="value">R$ {{ cards.waitingFunds | currencyEnToBr }}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="6" v-if="hasBalance">
        <div class="list-card" v-loading="cards.loading">
          <el-button @click="createTransfer">Solicitar Saque</el-button>
        </div>
      </el-col>
    </el-row>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtrar Listagem</span>
            <div>
              <el-button type="primary" @click="onFilter" size="small">
                <i class="fas fa-filter"></i>
                <span>Pesquisar</span>
              </el-button>
              <el-button type="default" @click="onFilterClean" size="small">
                <i class="fas fa-broom"></i>
                <span>Limpar</span>
              </el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters" class="demo-form-inline">
            <el-form-item label="Cliente">
              <el-input v-model="filters.client"
                        placeholder="Filtrar por Cliente"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Centros de Custo">
              <el-select v-model="filters.classification_ids"
                         filterable
                         clearable
                         multiple
                         placeholder="Filtrar por Centros de Custo"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full">
                <el-option
                    v-for="item in dependencies.classifications"
                    :key="item.id"
                    :label="item.description"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Período">
              <el-date-picker
                  v-model="filters.dates"
                  type="daterange"
                  format="dd/MM/yyyy"
                  placeholder="Filtrar por vencimento">
              </el-date-picker>
              <div class="text-center pt-3" v-if="!filters.status || (filters.status && filters.status === 'settled')">
                <el-form-item>
                  <el-radio v-model="filters.due_date" :label="true">Dt. Vencimento</el-radio>
                  <el-radio v-model="filters.due_date" :label="false">Dt. Pagamento</el-radio>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label="Status">
              <el-select v-model="filters.status"
                         filterable
                         clearable
                         placeholder="Filtrar por Status"
                         empty-text="Nenhum registro encontrado">
                <el-option v-for="item in dependencies.status"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Mostrar Lançamentos Antigos" v-if="filters.status !== 'settled'">
              <el-switch v-model="filters.show_opened_entries"
                         :active-value="true"
                         :inactive-value="false"
                         active-text="SIM"
                         inactive-text="NÃO">
              </el-switch>
            </el-form-item>
            <el-form-item label="Cliente com NFSe Ativa">
              <el-select v-model="filters.has_nfse">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Sim" value="yes"/>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="headerTitle">
        <span>Listagem</span>
        <div class="entries-selected d-inline-block">
          <el-dropdown :disabled="selectedList.length === 0"
                       @command="selectedAction"
                       v-if="selectedList.length > 0">
            <el-button :type="selectedList.length === 0 ? 'default' : 'primary'" size="small">
              <span>Com selecionados:</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Quitar">Quitar</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div slot="headerList">
        <div class="legends">
          <div class="legend-entry">
            <span>Legendas:</span>
          </div>
          <div class="legend-entry">
            <span class="label label-default"></span>
            <span>Aguardando pagamento</span>
          </div>
          <div class="legend-entry">
            <span class="label label-danger"></span>
            <span>Em Atraso</span>
          </div>
          <div class="legend-entry">
            <span class="label label-success"></span>
            <span>Quitada</span>
          </div>
          <div class="legend-entry">
            <span class="label label-warning"></span>
            <span>Quitada com Atraso</span>
          </div>
        </div>
      </div>

      <div slot="list" v-if="!isLoadingData">
        <table class="custom-table-list">
          <thead>
          <tr>
            <th></th>
            <th>
              <el-checkbox @change="checkAll" :value="isAllChecked"/>
            </th>
            <th>Vencimento</th>
            <th>Cliente</th>
            <th>Classificação</th>
            <th>Descrição</th>
            <th>Status</th>
            <th>Valor</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in listStore.listData"
              :class="{'row-danger': item.overdue}">
            <td class="list-table-nowrap">
              <entry-status-circle :item="item"/>
            </td>
            <th class="list-table-nowrap">
              <el-checkbox @change="checkItem(item)" v-model="item.checked" :disabled="!item.id"/>
            </th>
            <td>
              <div>{{ item.due_date | dateEnToBr }}</div>
              <div v-if="item.notification_at">Notify at: {{ item.notification_at | dateEnToBr }}</div>
            </td>
            <td>
              <RouterLink class="base-link" :to="{name: 'client.update', params: {id: item.entry.client_id}}" targer="_blank">
                {{ item.entry.client.name }}
                ( {{ item.entry.client.cpf_cnpj }} )
              </RouterLink>
            </td>
            <td>{{ item.entry.classification.description }}</td>
            <td>{{ item.entry ? item.entry.description : item.description }}</td>
            <td>
              <div>
                <entry-status :status="item.status"/>
              </div>
              <div v-if="item.status === 'settled'" class="settled-at">Em: {{
                  item.pay_date |
                      dateEnToBr
                }}
              </div>
            </td>
            <td align="right">
              R$ {{ currencyEnToBr(item.status === 'settled' ? item.amount_paid : item.amount) }}
            </td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <a class="el-button el-button--warning"
                   :href="item.payment_url"
                   target="_blank"
                   v-if="item.payment_url"
                   key="paymentUrl"
                   size="small"
                   title="Abrir Pagamento">
                  <i class="fas fa-barcode"></i>
                </a>
                <el-button type="warning"
                           size="small"
                           key="showTransaction"
                           v-if="!item.payment_url"
                           @click="showTransaction(item)"
                           title="Gerar Pagamento">
                  <i class="fas fa-barcode"></i>
                </el-button>
                <el-button type="primary"
                           size="small"
                           key="generateInvoice"
                           v-if="item.entry.client.generate_nfse && !item.invoice && item.status === 'settled'"
                           @click="generateInvoice(item)"
                           title="Gerar NFSe">
                  <i class="fas fa-file-invoice"></i>
                </el-button>
                <el-button type="primary"
                           size="small"
                           key="showInvoice"
                           v-if="item.invoice"
                           @click="showInvoice(item)"
                           title="Abrir NFSe">
                  <i class="fas fa-file-invoice"></i>
                </el-button>
                <el-button type="success"
                           size="small"
                           v-if="item.status === 'created'"
                           key="openSettlePortion"
                           @click="openSettlePortion(item)"
                           title="Quitar">
                  <i class="far fa-money-bill-alt"></i>
                </el-button>
                <ListShowButton route-name="entry.revenue.show" :item="item.entry"/>
                <el-button type="danger"
                           size="small"
                           key="cancelRecurrent"
                           v-if="item.entry && item.entry.recurrent"
                           @click="onDestroy(item)"
                           title="Cancelar Recorrência">
                  <i class="fas fa-trash"></i>
                </el-button>
                <el-button type="danger"
                           size="small"
                           key="deletePortion"
                           v-if="item.entry && !item.entry.recurrent"
                           @click="onDestroy(item)"
                           title="Remover Lançamento">
                  <i class="fas fa-trash"></i>
                </el-button>
              </div>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th colspan="8" align="right">Total Aberto:</th>
            <th align="right">R$ {{ totalAmountCreated }}</th>
            <th></th>
          </tr>
          <tr>
            <th colspan="8" align="right">Total Recebido:</th>
            <th align="right">R$ {{ totalAmountSettled }}</th>
            <th></th>
          </tr>
          </tfoot>
        </table>

      </div>

    </ListMain>

    <el-dialog title="Quitar Vários"
               :visible.sync="showModalSettledSelected"
               width="50%"
               top="1vh">
      <h3>Forma de Pagamento</h3>
      <el-select v-model="paymentMethodId"
                 filterable
                 clearable
                 class="el-select-full"
                 placeholder="Selecione um forma de pagamento"
                 empty-text="Nenhum registro encontrado">
        <el-option v-for="item in dependencies.payment_methods"
                   :key="item.id"
                   :label="item.description"
                   :value="item.id">
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleModalSettledSelected()">Cancelar</el-button>
        <el-button type="primary"
                   :disabled="selectedList.length === 0 || !paymentMethodId"
                   @click="settledSelected()">
          Quitar
        </el-button>
      </div>
    </el-dialog>

    <el-dialog title="Valor do Boleto"
               :visible.sync="showModalAmountBankSlip"
               width="70%"
               top="1vh">
      <el-form v-model="portion" label-position="top">
        <el-form-item label="Valor Pago"
                      class="is-required"
                      :class="{ 'el-form-item--error': $v.amountBankSlip.$error }">
          <el-input v-model.lazy="amountBankSlip"
                    v-money="moneyMask"
                    @input="$v.amountBankSlip.$touch()"/>
          <validation-message v-if="$v.amountBankSlip.$error"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleModalAmountBankSlip">Cancelar</el-button>
        <el-button type="primary"
                   :disabled="$v.amountBankSlip.$invalid"
                   @click="generateBankSlip(portion)">
          Gerar Boleto
        </el-button>
      </div>
    </el-dialog>

    <el-dialog title="Quitar Lançamento"
               :visible.sync="showModalSettledPortion"
               width="70%"
               top="1vh"
               custom-class="finish-order-dialog">
      <el-form v-model="portion" label-position="top">
        <el-form-item label="Forma de Pagamento"
                      class="is-required"
                      :class="{ 'el-form-item--error': $v.portion.payment_method_id.$error }">
          <el-select v-model="portion.payment_method_id"
                     @input="$v.portion.payment_method_id.$touch()"
                     class="el-select-full"
                     placeholder="Selecione um forma de pagamento"
                     empty-text="Nenhum registro encontrado">
            <el-option v-for="item in dependencies.payment_methods"
                       :key="item.id"
                       :label="item.description"
                       :value="item.id">
            </el-option>
          </el-select>
          <validation-message v-if="$v.portion.payment_method_id.$error"/>
        </el-form-item>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="Data de Pagamento"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.portion.pay_date.$error }">
              <el-date-picker
                  v-model="portion.pay_date"
                  @input="$v.portion.pay_date.$touch()"
                  type="date"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  placeholder="Data de Pagamento">
              </el-date-picker>
              <validation-message v-if="$v.portion.pay_date.$error"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Valor Pago"
                          class="is-required"
                          :class="{ 'el-form-item--error': $v.portion.amount_paid.$error }">
              <el-input v-model.lazy="portion.amount_paid"
                        v-money="moneyMask"
                        @input="$v.portion.amount_paid.$touch()"/>
              <validation-message v-if="$v.portion.amount_paid.$error"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="Descrição/Observações">
          <el-input type="textarea"
                    :rows="4"
                    v-model="portion.description"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleModalSettledPortion()">Cancelar</el-button>
        <el-button type="primary"
                   :disabled="isSubmitted || $v.portion.$invalid"
                   @click="settledPortion()">
          Quitar
        </el-button>
      </div>
    </el-dialog>

    <el-dialog title="Visualizando PDF"
               :visible.sync="showModalPdf"
               width="80%">
      <div class="modal-pdf">
        <iframe :src="pdfBase64"></iframe>
      </div>
      <div slot="footer" class="dialog-footer">
        <div/>
        <el-button @click="showModalPdf = false">
          Fechar
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
