import ListRevenue from './components/ListRevenue'
import FormRevenue from './components/FormRevenue'
import ShowRevenue from './components/ShowRevenue'
import ListExpense from './components/ListExpense'
import FormExpense from './components/FormExpense'
import ShowExpense from './components/ShowExpense'

export default [
  {path: '/entry/revenue', component: ListRevenue, name: 'entry.revenue.index', meta: {permission: 'entry.search'}},
  {path: '/entry/revenue/store', component: FormRevenue, name: 'entry.revenue.store', meta: {permission: 'entry.store'}, props: true},
  {path: '/entry/revenue/:id', component: FormRevenue, name: 'entry.revenue.update', meta: {permission: 'entry.update'}, props: true},
  {path: '/entry/revenue/:id/show', component: ShowRevenue, name: 'entry.revenue.show', meta: {permission: 'entry.show'}},
  {path: '/entry/expense', component: ListExpense, name: 'entry.expense.index', meta: {permission: 'entry.search'}},
  {path: '/entry/expense/store', component: FormExpense, name: 'entry.expense.store', meta: {permission: 'entry.store'}, props: true},
  {path: '/entry/expense/:id', component: FormExpense, name: 'entry.expense.update', meta: {permission: 'entry.update'}, props: true},
  {path: '/entry/expense/:id/show', component: ShowExpense, name: 'entry.expense.show', meta: {permission: 'entry.show'}}
]
