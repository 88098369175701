<script>
  export default {
    name: 'contacts-label',
    props: {
      type: {default: '', type: String}
    }
  }
</script>

<template>
    <div class="label-container">
        <span class="label-icon"><i class="fas fa-phone" v-if="type === 'phone'"></i></span>
        <span class="label-text" v-if="type === 'phone'">Telefone</span>
        <span class="label-icon"><i class="fas fa-envelope" v-if="type === 'email'"></i></span>
        <span class="label-text" v-if="type === 'email'">Email</span>
        <span class="label-icon"><i class="fas fa-random" v-if="type === 'other'"></i></span>
        <span class="label-text" v-if="type === 'other'">Outro</span>
    </div>
</template>
