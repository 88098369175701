import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import beforeEach from './beforeEach'
import afterEach from './afterEach'

Vue.use(VueRouter)

const router = new VueRouter(
  {
    mode: 'history',
    routes,
    linkActiveClass: 'active'
  }
)

// setup a before each for routes.
router.beforeEach(beforeEach)

// setup a after each for routes.
router.afterEach(afterEach)

export default router
