<script>
import { clone } from 'lodash'
import { integer, required } from 'vuelidate/lib/validators'
import * as restfulService from '../../../app/services/restfulService'
import * as notifyService from '../../../app/services/notifyService'
import bus from '@utils/bus'
import helpers from '@mixins/helpers';

export default {
  mixins: [helpers],
  name: 'form-client-payment-accounts',
  props: {
    ownerId: {
      type: integer
    },
    list: {
      type: Array,
      default: () => []
    },
    dependencies: {
      type: Object,
      default: () => []
    },
    cpfCnpj: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showDialog: false,
      clientPaymentAccounts: [],
      form: {...defaultFormData()}
    }
  },
  mounted() {
    this.clientPaymentAccounts = this.list
  },
  methods: {
    showForm() {
      this.form.cpf_cnpj = this.onlyNumbers(this.cpfCnpj)
      this.showDialog = true
    },
    closeForm() {
      this.showDialog = false
    },
    onSave() {
      let formData = clone(this.form)
      formData.client_id = this.ownerId
      restfulService.save('client_payment_account', null, null, formData)
          .then(async (response) => {
            const paymentAccount = await restfulService.get('client_payment_account', null, response.id, ['*'], ['bank'])
            this.clientPaymentAccounts.push(paymentAccount)
            this.form = defaultFormData()
            this.showDialog = false
            this.$emit('update:list', this.clientPaymentAccounts)
          })
          .catch(e => {
            console.log(e)
          })
    },
    onDelete(clientPaymentAccount) {
      restfulService.destroy('client_payment_account', null, clientPaymentAccount.id)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.clientPaymentAccounts = this.clientPaymentAccounts.filter(account => account.id !== clientPaymentAccount.id)
            this.$emit('update:list', this.clientPaymentAccounts)
          })
          .catch(e => {
            bus.$emit('hide-loader')
            console.log(e)
          })
    },
  },
  validations: {
    form: {
      cpf_cnpj: {required},
      agency: {required},
      account: {required},
      digit: {required},
      main: {required}
    }
  }
}

function defaultFormData() {
  return {
    cpf_cnpj: '',
    bank_code: '',
    agency: '',
    account: '',
    digit: '',
    gateway_account_id: '',
    main: false,
    new_bank: {
      name: '',
      code: ''
    }
  };
}
</script>

<template>
  <div>
    <el-card class="el-card__save_form box-card">
      <div slot="header">
        <span>Contas de Pagamento</span>
        <el-button size="small"
                   type="primary"
                   @click="showForm">
          <i class="fas fa-plus"></i>
          <span>Adicionar Conta de Pagamento</span>
        </el-button>
      </div>
      <table class="custom-table-list">
        <thead>
        <tr>
          <th>Banco</th>
          <th>Agência</th>
          <th>Conta</th>
          <th>Recebedor</th>
          <th>Princial</th>
          <th class="btn-actions">Ações</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="clientPaymentAccount in clientPaymentAccounts" :key="clientPaymentAccount.id">
          <td>
            <span v-if="clientPaymentAccount.bank">
              ({{ clientPaymentAccount.bank.code }}) {{ clientPaymentAccount.bank.name }}
            </span>
          </td>
          <td>{{ clientPaymentAccount.agency }}</td>
          <td>{{ clientPaymentAccount.account }}-{{ clientPaymentAccount.digit }}</td>
          <td>
            <div>CPF/CNPJ: {{ clientPaymentAccount.cpf_cnpj }}</div>
            <div>{{ clientPaymentAccount.gateway_account_id }}</div>
          </td>
          <td>{{ clientPaymentAccount.main ? 'Sim' : 'Não' }}</td>
          <td>
            <div class="btn-actions">
              <el-button type="danger"
                         size="small"
                         title="Remover conta de pagamento"
                         @click="onDelete(clientPaymentAccount)">
                <i class="fas fa-trash"></i>
              </el-button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </el-card>

    <el-dialog
        title="Nova Conta de Pagamento"
        v-if="showDialog"
        :visible.sync="showDialog">
      <el-form :model="form" ref="form">
        <el-form-item label="Banco">
          <el-select v-model="form.bank_code"
                     clearable
                     filterable
                     placeholder="Selecione um banco"
                     empty-text="Nenhum registro encontrado"
                     class="el-select-full"
                     @input="$v.form.bank_code.$touch()">
            <el-option v-for="item in dependencies.banks"
                       :key="item.id"
                       :label="`(${item.code}) ${item.name}`"
                       :value="item.code">
            </el-option>
          </el-select>
          <el-row :gutter="10" class="mt-2">
            <el-col :span="12">
              <el-form-item label="Nome do Banco">
                <el-input v-model="form.new_bank.name" :disabled="!!form.bank_code"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Código do Banco">
                <el-input v-model="form.new_bank.code" :disabled="!!form.bank_code"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="CPF/CNPJ">
          <el-input v-model="form.cpf_cnpj"></el-input>
        </el-form-item>
        <el-form-item label="Agência">
          <el-input v-model="form.agency"></el-input>
        </el-form-item>
        <el-form-item label="Conta">
          <el-input v-model="form.account"></el-input>
        </el-form-item>
        <el-form-item label="Dígito">
          <el-input v-model="form.digit"></el-input>
        </el-form-item>
        <el-form-item label="Principal">
          <el-switch v-model="form.main"
                     active-color="#13ce66"
                     active-text="SIM"
                     inactive-color="#ff4949"
                     inactive-text="NÃO">
          </el-switch>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeForm()">Fechar</el-button>
        <el-button
            type="primary"
            @click="onSave"
            :disabled="$v.$invalid">Salvar
        </el-button>
      </div>

    </el-dialog>
  </div>
</template>
