import {Notification} from 'element-ui'
import {merge} from 'lodash'
import bus from '@utils/bus'

function handleObjectData (options) {
  const defaultOptions = {
    title: 'Sucesso!',
    message: 'Os dados foram gravados',
    offset: 50,
    duration: 3000
  }
  return merge(defaultOptions, options)
}

function handleLoader (options) {
  if (options.hasOwnProperty('hideLoader') && options.hideLoader) {
    bus.$emit('hide-loader')
  }
}

function _success (options = {}) {
  options.title = 'Sucesso!'
  options = handleObjectData(options)
  handleLoader(options)
  Notification.success(options)
}

function _warning (options = {}) {
  options.title = 'Atenção!'
  options = handleObjectData(options)
  handleLoader(options)
  Notification.warning(options)
}

export const success = _success
export const warning = _warning
