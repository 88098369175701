<script>
import ListHeader from '../../../../components/list/Header.vue'
import FormSaveButton from '../../../../components/form/SaveButton.vue'
import FormCancelButton from '../../../../components/form/CancelButton.vue'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import DataService from '../../../../services/dataService'

import bus from '@utils/bus'
import helpers from '@mixins/helpers'

import { required } from 'vuelidate/lib/validators'
import { clone } from 'lodash'

export default {
  components: {ListHeader, FormSaveButton, FormCancelButton},
  mixins: [helpers],
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      isSearchingCity: false,
      form: {
        country_id: 1,
        city_id: '',
        name: '',
        type: '',
        cpf: '',
        email: '',
        phone: '',
        birth_date: '',
        sex: '',
        side: '',
        password: '',
      },
      emailVerified: false,
      phoneVerified: false,
      phonePrefix: '+55',
      dependencies: {
        cities: [],
        countries: [],
      },
    }
  },
  validations: {
    form: {
      country_id: {required},
      name: {required},
    },
  },
  computed: {
    phoneMask() {
      const country = this.dependencies.countries.find(item => item.id === this.form.country_id)
      if (country) {
        switch (country.code) {
          case 'URY':
            return '########'

          case 'ARG':
            return '##########'

          case 'PRY':
            return '#########'
        }
      }
      return '## #####-####'
    }
  },
  mounted() {
    this.getDependencies()
    const id = this.$route.params.id || null
    if (id) {
      this.isEdit = true
      restfulService.get('user', null, id, null, ['city', 'country'])
          .then(response => {
            if (response.city) {
              this.dependencies.cities.push(response.city)
            }
            this.form = {...response}
            this.emailVerified = !!response.email_verified_at
            this.phoneVerified = !!response.phone_verified_at
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
  },
  methods: {
    getDependencies() {
      return new Promise(resolve => {
        DataService.get([{domain: 'country'}]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
          resolve()
        })
      })
    },
    changeCountry(id) {
      const country = this.dependencies.countries.find(item => item.id === id)
      this.phonePrefix = '+' + country.phone_prefix
    },
    onSearchCity(query) {
      return new Promise((resolve, reject) => {
        this.dependencies.cities.splice(0)
        if (query !== '') {
          this.isSearchingCity = true
          let data = {query}
          restfulService.post('city', 'autocomplete', null, data)
              .then((response) => {
                this.dependencies.cities = [...this.dependencies.cities, ...response]
                this.isSearchingCity = false
                resolve()
              })
              .catch(e => {
                console.log(e)
                reject()
              })
        }
      })
    },
    onSend() {
      this.isSubmitted = true
      const data = clone(this.form)
      if (data.cpf) {
        data.cpf = this.fixCpfLength(data.cpf)
      }
      if (data.phone) {
        data.phone = this.onlyNumbers(data.phone)
      }
      data.force_email_verify = this.emailVerified
      data.force_phone_verify = this.phoneVerified
      restfulService.save('user', null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'user.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div v-if="!isLoadingData">

    <ListHeader title="Usuários"/>

    <el-form ref="form"
             :model="form"
             label-position="top"
             v-on:submit.prevent="onSend">
      <el-card class="el-card__save_form box-card">
        <div slot="header">
          <span>Dados do Usuário</span>
        </div>
        <el-form-item label="País"
                      class="is-required"
                      :class="{ 'el-form-item--error': $v.form.country_id.$error }">
          <el-select v-model="form.country_id"
                     @input="$v.form.country_id.$touch()"
                     class="el-select-full"
                     placeholder="Selecione um país"
                     empty-text="Nenhum registro encontrado">
            <el-option v-for="item in dependencies.countries"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
          <div class="el-form-item__error" v-if="$v.form.country_id.$error">
            Campo obrigatório
          </div>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Nome">
              <el-input v-model="form.name"
                        @input="$v.form.name.$touch()"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Sexo">
              <el-select v-model="form.sex"
                         class="el-select-full">
                <el-option label="Masculino" value="male"/>
                <el-option label="Feminino" value="female"/>
                <el-option label="Não informar" value="not_informed"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Data de Nascimento">
              <el-date-picker
                  v-model="form.birth_date"
                  type="date"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  v-mask="'##/##/####'"
                  placeholder="Data de Nascimento">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="E-mail">
              <el-input type="email" v-model="form.email"/>
              <h3 class="mt-3">
                Verificado
                <el-switch v-model="emailVerified" :disabled="!form.email"/>
              </h3>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="CPF/DNI/CI">
              <el-input type="tel"
                        v-model="form.cpf"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Phone">
              <el-input v-model="form.phone"
                        type="tel"
                        v-mask="phoneMask">
                <template slot="prepend">{{ phonePrefix }}</template>
              </el-input>
              <h3 class="mt-3">
                Verificado
                <el-switch v-model="phoneVerified" :disabled="!form.phone"/>
              </h3>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Status"
                          class="is-required">
              <el-select v-model="form.status"
                         class="el-select-full">
                <el-option label="Ativo" value="active"/>
                <el-option label="Inativo" value="inactive"/>
                <el-option label="Não confirmado" value="not_confirmed"/>
                <el-option label="Bloqueado" value="blocked"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Cidade">
              <el-select v-model="form.city_id"
                         clearable
                         filterable
                         remote
                         :remote-method="onSearchCity"
                         :loading="isSearchingCity"
                         placeholder="Selecione uma cidade"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full">
                <el-option v-for="item in dependencies.cities"
                           :key="item.id"
                           :label="item.name_with_state"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Senha">
              <el-input v-model="form.password"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="Lado de Preferência">
          <el-select v-model="form.side"
                     class="el-select-full">
            <el-option label="Ambos" value="both"/>
            <el-option label="Drive" value="right"/>
            <el-option label="Revés" value="left"/>
          </el-select>
        </el-form-item>
      </el-card>
      <el-card class="el-card__btn_form">
        <el-form-item class="mb-0">
          <form-save-button :event-click="onSend"
                            :is-disabled="isSubmitted || $v.form.$invalid"
                            :is-submitted="isSubmitted"/>
          <form-cancel-button route-name="user.index"/>
        </el-form-item>
      </el-card>
    </el-form>
  </div>
</template>
