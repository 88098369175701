<script>
  import ListHeader from '../../../../components/list/Header.vue'
  import FormSaveButton from '../../../../components/form/SaveButton.vue'
  import FormCancelButton from '../../../../components/form/CancelButton.vue'

  import * as restfulService from '../../../../services/restfulService'
  import * as notifyService from '../../../../services/notifyService'
  import DataService from '../../../../services/dataService'

  import bus from '@utils/bus'

  import {required} from 'vuelidate/lib/validators'
  import {clone} from 'lodash'

  export default {
    components: {ListHeader, FormSaveButton, FormCancelButton},
    data () {
      return {
        isEdit: false,
        isSubmitted: false,
        isLoadingData: true,
        form: {
          name: ''
        },
        domains: []
      }
    },
    validations: {
      form: {
        name: {required}
      }
    },
    mounted () {
      this.getDependencies()
      const id = this.$route.params.id || null
      if (id) {
        this.isEdit = true
        restfulService.get('role', null, id, null, ['permissions'])
          .then(response => {
            this.domains.forEach((domain) => {
              domain.permissions.forEach((permission) => {
                permission.checked = response.permissions.some((item) => {
                  return item.id === permission.id
                })
              })
              domain.checked = domain.permissions.some((item) => {
                return item.checked
              })
            })
            this.form = response
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
      } else {
        this.isLoadingData = false
        bus.$emit('hide-loader')
      }
    },
    methods: {
      getDependencies () {
        DataService.get([{domain: 'permission'}]).then((result) => {
          result.permissions.forEach((val) => {
            let nameSplit = val.name.split('.')
            let realNameSplit = val.readable_name.split('|')
            let name = realNameSplit[1] + ' ' + realNameSplit[0]
            let exists = this.domains.some((val) => {
              return val.id === nameSplit[0]
            })
            if (exists === false) {
              this.domains.push({
                id: nameSplit[0],
                name: realNameSplit[0],
                permissions: [],
                selecteds: [],
                checked: true
              })
            }
            let item = this.domains.filter((val) => {
              return val.id === nameSplit[0]
            })
            item[0].permissions.push({
              id: val.id,
              readable_name: name,
              checked: true
            })
          })
        })
      },
      onSelectAll (domain) {
        domain.permissions.forEach((permission) => {
          permission.checked = domain.checked
        })
      },
      onSend () {
        this.isSubmitted = true
        const data = clone(this.form)
        data.permissions = []
        this.domains.forEach((domain) => {
          domain.permissions.forEach((permission) => {
            if (permission.checked) {
              data.permissions.push(permission)
            }
          })
        })
        restfulService.save('role', null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'role.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
      }
    }
  }
</script>

<template>
    <div v-if="!isLoadingData">

        <ListHeader title="Perfis de Usuário"/>

        <el-card class="el-card__save_form box-card">
            <div slot="header">
                <span>Dados do Perfil</span>
            </div>
            <el-form ref="form"
                     :model="form"
                     label-position="top"
                     v-on:submit.prevent="onSend">
                <el-form-item label="Nome"
                              class="is-required"
                              v-bind:class="{ 'el-form-item--error': $v.form.name.$error }">
                    <el-input v-model="form.name"
                              @input="$v.form.name.$touch()"/>
                    <div class="el-form-item__error" v-if="$v.form.name.$error">Esse campo é obrigatório</div>
                </el-form-item>
                <el-form-item>
                    <h3>Permissões</h3>
                    <div class="permissions-box">
                        <el-card class="box-card" v-for="domain in domains">
                            <div slot="header" class="clearfix">
                                <el-checkbox v-model="domain.checked"
                                             @change="onSelectAll(domain)">
                                    {{domain.name}}
                                </el-checkbox>
                            </div>
                            <div>
                                <div v-for="permission in domain.permissions">
                                    <el-checkbox v-model="permission.checked">
                                        {{permission.readable_name}}
                                    </el-checkbox>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </el-form-item>
                <el-form-item>
                    <form-save-button :event-click="onSend"
                                      :is-disabled="isSubmitted || $v.$invalid"
                                      :is-submitted="isSubmitted">
                    </form-save-button>
                    <form-cancel-button route-name="role.index"></form-cancel-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<style lang="scss">
    .permissions-box {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        .box-card {
            width: 23.5%;
            margin: 0 10px 20px;
            .el-card__header {
                padding: 4px 20px;
                font-weight: bold;
            }
            .el-card__body {
                padding: 10px 20px;
            }
        }
    }
</style>
