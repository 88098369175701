<script>
  import ListHeader from '../../../../components/list/Header.vue'
  import FormSaveButton from '../../../../components/form/SaveButton.vue'
  import FormCancelButton from '../../../../components/form/CancelButton.vue'

  import DataService from '../../../../services/dataService'
  import * as restfulService from '../../../../services/restfulService'
  import * as notifyService from '../../../../services/notifyService'

  import bus from '@utils/bus'

  import {required, minLength, maxLength} from 'vuelidate/lib/validators'
  import {clone} from 'lodash'

  export default {
    components: {ListHeader, FormSaveButton, FormCancelButton},
    data () {
      return {
        isEdit: false,
        isSubmitted: false,
        isLoadingData: true,
        form: {
          state_id: '',
          name: '',
          initials: ''
        },
        dependencies: {}
      }
    },
    validations: {
      form: {
        state_id: {required},
        name: {required},
        initials: {minLength: minLength(2), maxLength: maxLength(3)}
      }
    },
    mounted () {
      this.getDependencies()
      const id = this.$route.params.id || null
      if (id) {
        this.isEdit = true
        restfulService.get('city', null, id)
          .then(response => {
            this.form = response
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
      } else {
        this.isLoadingData = false
        bus.$emit('hide-loader')
      }
    },
    methods: {
      getDependencies () {
        DataService.get([{domain: 'state'}]).then((result) => {
          this.dependencies = result
        })
      },
      onSend () {
        this.isSubmitted = true
        const data = clone(this.form)
        restfulService.save('city', null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'city.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
      }
    }
  }
</script>

<template>
    <div v-if="!isLoadingData">

        <ListHeader title="Cidades"/>

        <el-card class="el-card__save_form box-card">
            <div slot="header">
                <span>Dados das Cidades</span>
            </div>
            <el-form ref="form"
                     :model="form"
                     label-width="120px"
                     v-on:submit.prevent="onSend">
                <el-form-item label="Estado"
                              class="is-required"
                              v-bind:class="{ 'el-form-item--error': $v.form.state_id.$error }">
                    <el-select v-model="form.state_id"
                               filterable
                               placeholder="Selecione um estado"
                               empty-text="Nenhum registro encontrado"
                               class="el-select-full"
                               value=""
                               @input="$v.form.state_id.$touch()">
                        <el-option
                                v-for="item in dependencies.states"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                    <div class="el-form-item__error" v-if="$v.form.state_id.$error">
                        Esse campo é obrigatório
                    </div>
                </el-form-item>
                <el-form-item label="Nome"
                              class="is-required"
                              v-bind:class="{ 'el-form-item--error': $v.form.name.$error }">
                    <el-input v-model="form.name"
                              @input="$v.form.name.$touch()"
                              :maxlength="100"/>
                    <div class="el-form-item__error" v-if="$v.form.name.$error">Esse campo é obrigatório</div>
                </el-form-item>
                <el-form-item label="Sigla"
                              class="is-required"
                              v-bind:class="{ 'el-form-item--error': $v.form.initials.$error }">
                    <el-input v-model="form.initials"
                              @input="$v.form.initials.$touch()"
                              :maxlength="3" :minlength="2"/>
                    <div class="el-form-item__error" v-if="$v.form.initials.$error">Esse campo requer pelo menos 2 digitos</div>
                </el-form-item>
                <el-form-item>
                    <form-save-button :event-click="onSend"
                                      :is-disabled="isSubmitted || $v.$invalid"
                                      :is-submitted="isSubmitted">
                    </form-save-button>
                    <form-cancel-button route-name="city.index"/>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
