<script>
  import ListHeader from '../../../../components/list/Header.vue'
  import FormSaveButton from '../../../../components/form/SaveButton.vue'
  import FormCancelButton from '../../../../components/form/CancelButton.vue'

  import * as restfulService from '../../../../services/restfulService'
  import * as notifyService from '../../../../services/notifyService'

  import bus from '@utils/bus'

  import {required} from 'vuelidate/lib/validators'
  import {clone} from 'lodash'

  export default {
    components: {ListHeader, FormSaveButton, FormCancelButton},
    data () {
      return {
        isEdit: false,
        isSubmitted: false,
        isLoadingData: true,
        form: {
          type: 'revenue',
          description: ''
        },
        dependencies: {}
      }
    },
    validations: {
      form: {
        description: {required}
      }
    },
    mounted () {
      const id = this.$route.params.id || null
      if (id) {
        this.isEdit = true
        restfulService.get('entry_classification', null, id)
          .then(response => {
            this.form = response
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
      } else {
        this.isLoadingData = false
        bus.$emit('hide-loader')
      }
    },
    methods: {
      onSend () {
        this.isSubmitted = true
        const data = clone(this.form)
        restfulService.save('entry_classification', null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'entry_classification.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
      }
    }
  }
</script>

<template>
    <div v-if="!isLoadingData">
        <ListHeader title="Centros de Custo"/>
        <el-card class="el-card__save_form box-card">
            <div slot="header">
                <span>Dados do Centro de Custo</span>
            </div>
            <el-form ref="form"
                     :model="form"
                     label-width="120px"
                     v-on:submit.prevent="onSend">
                <el-form-item label="Tipo"
                              class="is-required">
                    <el-radio-group v-model="form.type">
                        <el-radio-button label="revenue">Receita</el-radio-button>
                        <el-radio-button label="expense">Despesa</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="Descrição"
                              class="is-required"
                              v-bind:class="{ 'el-form-item--error': $v.form.description.$error }">
                    <el-input v-model="form.description"
                              @input="$v.form.description.$touch()"/>
                    <div class="el-form-item__error" v-if="$v.form.description.$error">Esse campo é obrigatório</div>
                </el-form-item>
                <el-form-item>
                    <form-save-button :event-click="onSend"
                                      :is-disabled="isSubmitted || $v.$invalid"
                                      :is-submitted="isSubmitted">
                    </form-save-button>
                    <form-cancel-button route-name="entry_classification.index"/>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
