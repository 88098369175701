import * as TYPES from './types'
import { clear, set } from '../utils/storage'

export default {
  showLoader: (context) => {
    context.commit(TYPES.LOADER_SHOW, true)
  },
  hideLoader: (context) => {
    setTimeout(() => {
      context.commit(TYPES.LOADER_SHOW, false)
    }, 300)
  },
  toggleSidebar: (context) => {
    context.commit(TYPES.TOGGLE_SIDEBAR, !context.state.showSidebar)
  },
  showNotification: (context, payload) => {
    context.commit(TYPES.NOTIFICATION, payload)
  },
  setListOptions: (context, payload) => {
    context.commit(TYPES.LIST_OPTIONS, payload)
  },
  setListData: (context, payload) => {
    context.commit(TYPES.LIST_DATA, payload)
  },
  setListSort (context, payload) {
    context.commit(TYPES.LIST_SORT, payload)
  },
  setListFilters (context, payload) {
    context.commit(TYPES.LIST_FILTERS, payload)
  },
  setCurrentPage (context, payload) {
    context.commit(TYPES.LIST_CURRENT_PAGE, payload)
  },
  setListMode (context, payload) {
    context.commit(TYPES.LIST_MODE, payload)
  },
  async setSortField ({commit, getters}, payload) {
    return new Promise(async (resolve) => {
      let field = null
      if (payload) {
        field = getters.listStore.listSort.find(item => item.column === payload)
        const sortList = getters.listStore.listSort.filter(item => item.column !== payload)
        if (field) {
          if (field.direction === 'asc') {
            field.direction = 'desc'
          } else {
            field = null
          }
        } else {
          field = {
            column: payload,
            direction: 'asc'
          }
        }
        if (field) {
          if (getters.listStore.listMode === 'single') {
            sortList.splice(0)
          }
          sortList.push(field)
        }
        await commit(TYPES.LIST_SORT, sortList)
      }
      resolve()
    })
  },
  login: ({dispatch}, payload) => {
    return new Promise((resolve) => {
      const data = {
        token: payload.token,
        user: payload.user,
        permissions: payload.permissions
      }
      dispatch('setUpAuth', data).then(() => resolve())
    })
  },
  logout: (context) => {
    return new Promise((resolve) => {
      clear()
      context.commit(TYPES.AUTH_USER, {})
      context.commit(TYPES.AUTH_PERMISSIONS, [])
      context.commit(TYPES.AUTH_TOKEN, '')
      resolve()
    })
  },
  setUpAuth: (context, payload) => {
    return new Promise((resolve) => {
      set('user', payload.user)
      set('permissions', payload.permissions)
      set('token', payload.token)
      context.commit(TYPES.AUTH_USER, payload.user)
      context.commit(TYPES.AUTH_PERMISSIONS, payload.permissions)
      context.commit(TYPES.AUTH_TOKEN, payload.token)
      resolve()
    })
  }
}
