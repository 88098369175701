import {routes as auth} from './auth'
import {routes as user} from './user'
import {routes as role} from './role'
import {routes as log} from './log'
import {routes as notification} from './notification'
import {routes as adminUser} from './admin_user'

export default [
    ...auth,
    ...user,
    ...role,
    ...log,
    ...notification,
    ...adminUser,
]
