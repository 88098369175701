<script>
  import ListHeader from '../../../../components/list/Header.vue'
  import ValidationMessage from '../../../../components/form/ValidationMessage.vue'
  import EntryStatusCircle from './labels/StatusCircle.vue'
  import EntryIntervalType from './labels/IntervalType.vue'

  import * as restfulService from '../../../../services/restfulService'
  import * as notifyService from '../../../../services/notifyService'
  import DataService from '../../../../services/dataService'

  import helpers from '@mixins/helpers'
  import bus from '@utils/bus'

  import {required} from 'vuelidate/lib/validators'
  import moment from 'moment'
  import {clone} from 'lodash'

  export default {
    components: {ListHeader, ValidationMessage, EntryStatusCircle, EntryIntervalType},
    mixins: [helpers],
    data () {
      return {
        isEdit: false,
        isLoadingData: true,
        isSubmitted: false,
        isSettleModalVisible: false,
        form: {},
        dependencies: {
          payment_methods: []
        },
        portion: {
          id: '',
          payment_method_id: '',
          pay_date: '',
          amount_paid: '',
          description: ''
        },
        moneyMask: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          precision: 2,
          masked: false
        }
      }
    },
    validations: {
      portion: {
        payment_method_id: {required},
        pay_date: {required},
        amount_paid: {required}
      }
    },
    mounted () {
      this.getDependencies()
      const id = this.$route.params.id || null
      if (id) {
        this.isEdit = true
        restfulService.get('entry', null, id, null, ['client', 'classification', 'portions.payment_method'])
          .then(response => {
            response.portions.forEach((item) => {
              item.received_late = item.pay_date > item.due_date && item.status === 'settled'
              item.overdue = moment(item.due_date, 'YYYY-MM-DD') < moment() && item.status !== 'settled'
              if (item.amount_paid && item.amount_paid < item.amount) {
                item.outstanding = true
              }
            })
            this.form = response
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
      } else {
        this.isLoadingData = false
        bus.$emit('hide-loader')
      }
    },
    methods: {
      getDependencies () {
        DataService.get([{domain: 'payment_method'}]).then((result) => {
          this.dependencies = {...this.dependencies, ...result}
        })
      },
      getPortion (id) {
        return new Promise((resolve, reject) => {
          restfulService.get('entry_portion', null, id, null, ['payment_method'])
            .then(response => {
              response.received_late = response.pay_date > response.due_date && response.status === 'settled'
              response.overdue = moment(response.due_date, 'YYYY-MM-DD') < moment() && response.status !== 'settled'
              if (response.amount_paid && response.amount_paid < response.amount) {
                response.outstanding = true
              }
              this.isLoadingData = false
              bus.$emit('hide-loader')
              resolve(response)
            })
            .catch(e => {
              console.log(e)
              this.isLoadingData = false
              bus.$emit('hide-loader')
              reject(e)
            })
        })
      },
      handleSettleModalVisible () {
        this.isSettleModalVisible = !this.isSettleModalVisible
      },
      settlePortion (portion) {
        this.handleSettleModalVisible()
        let portionData = clone(portion)
        portionData.amount_paid = this.currencyEnToBr(portionData.amount)
        portionData.pay_date = this.dateNow('en')
        this.portion = clone(portionData)
      },
      revertPortion (portion) {
        this.isSubmitted = true
        let data = {}
        restfulService.put('entry_portion', 'revert', portion.id, data)
          .then(_ => {
            notifyService.success({hideLoader: true})
            if (!this.form.recurrent) {
              this.getPortion(portion.id).then(response => {
                this.$set(portion, Object.assign(portion, response))
                this.isSubmitted = false
              }).catch(e => {
                this.isSubmitted = false
                console.log(e)
              })
            } else {
              this.form.portions = this.form.portions.filter(item => item.id !== portion.id)
            }
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
      },
      savePortion (portion) {
        this.isSubmitted = true
        const data = clone(portion)
        data.amount_paid = this.currencyBrToEn(data.amount_paid)
        restfulService.put('entry_portion', 'finish', data.id, data)
          .then(_ => {
            let portion = this.form.portions.find(item => item.id === data.id)
            this.getPortion(portion.id).then(response => {
              this.$set(portion, Object.assign(portion, response))
              notifyService.success({hideLoader: true})
              this.handleSettleModalVisible()
              this.isSubmitted = false
            }).catch(e => {
              this.isSubmitted = false
              console.log(e)
            })
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
      }
    }
  }
</script>

<template>
    <div v-if="!isLoadingData">
        <ListHeader title="Contas a Receber" back-route="entry.revenue.index"/>
        <el-card class="el-card__save_form box-card">
            <div slot="header">
                <span>Dados do Lançamento</span>
            </div>
            <el-row :gutter="20">
                <el-col :span="12">
                    <table class="table-show-data">
                        <tbody>
                        <tr>
                            <td><strong>Cliente</strong>:</td>
                            <td>{{form.client.name}}</td>
                        </tr>
                        <tr>
                            <td><strong>Classificação</strong>:</td>
                            <td>{{form.classification.description}}</td>
                        </tr>
                        <tr>
                            <td><strong>Data</strong>:</td>
                            <td>{{form.due_date | dateEnToBr}}</td>
                        </tr>
                        <tr>
                            <td><strong>Descrição</strong>:</td>
                            <td>{{form.description}}</td>
                        </tr>
                        </tbody>
                    </table>
                </el-col>
                <el-col :span="12">
                    <table class="table-show-data">
                        <tbody>
                        <tr>
                            <td><strong>Valor Total (R$)</strong>:</td>
                            <td>{{form.amount | currencyEnToBr}}</td>
                        </tr>
                        <tr>
                            <td><strong>Recorrente</strong>:</td>
                            <td>{{form.recurrent ? 'Sim' : 'Não'}}</td>
                        </tr>
                        <tr v-if="form.recurrent">
                            <td><strong>Intervalo</strong>:</td>
                            <td>{{form.interval}}</td>
                        </tr>
                        <tr v-if="form.recurrent">
                            <td><strong>Tipo de Intervalo</strong>:</td>
                            <td><entry-interval-type :interval-type="form.interval_type"/></td>
                        </tr>
                        <tr v-if="!form.recurrent">
                            <td><strong>Parcelas</strong>:</td>
                            <td>{{form.portions_number}}</td>
                        </tr>
                        </tbody>
                    </table>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="el-card__save_form box-card">
            <div slot="header" class="flex-c">
                <span>Parcelas</span>
                <div class="legends">
                    <div class="legend-entry">
                        <span class="label label-default"></span>
                        <span>Aguardando pagamento</span>
                    </div>
                    <div class="legend-entry">
                        <span class="label label-danger"></span>
                        <span>Em Atraso</span>
                    </div>
                    <div class="legend-entry">
                        <span class="label label-success"></span>
                        <span>Quitada</span>
                    </div>
                    <div class="legend-entry">
                        <span class="label label-warning"></span>
                        <span>Quitada com Atraso</span>
                    </div>
                </div>
            </div>
            <div class="responsive-list">
                <table class="custom-table-list">
                    <thead>
                    <tr>
                        <th width="30"></th>
                        <th>Parcela</th>
                        <th>Descrição</th>
                        <th>Data Vencimento</th>
                        <th>Data Pagamento</th>
                        <th>Forma Pagto.</th>
                        <th align="right">Valor (R$)</th>
                        <th align="right">Valor Quitado (R$)</th>
                        <th width="60">Ações</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in form.portions">
                        <td>
                            <entry-status-circle :item="item"/>
                        </td>
                        <td>{{item.portion}}</td>
                        <td>{{item.description}}</td>
                        <td>{{item.due_date | dateEnToBr}}</td>
                        <td>{{item.pay_date | dateEnToBr}}</td>
                        <td>{{item.payment_method ? item.payment_method.description : ''}}</td>
                        <td align="right">{{item.amount | currencyEnToBr}}</td>
                        <td align="right">{{item.amount_paid | currencyEnToBr}}</td>
                        <td>
                            <el-button type="success"
                                       size="small"
                                       v-if="item.status === 'created'"
                                       @click="settlePortion(item)"
                                       title="Quitar">
                                <i class="far fa-money-bill-alt"></i>
                            </el-button>
                            <el-button type="warning"
                                       size="small"
                                       v-if="item.status === 'settled'"
                                       @click="revertPortion(item)"
                                       title="Reverter">
                                <i class="fas fa-retweet"></i>
                            </el-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </el-card>
        <el-dialog title="Quitar Parcela"
                   :before-close="() => handleSettleModalVisible()"
                   :visible.sync="isSettleModalVisible">
            <el-form :model="portion"
                     label-position="top">
                <el-form-item label="Forma de Pagamento"
                              class="is-required"
                              v-bind:class="{ 'el-form-item--error': $v.portion.payment_method_id.$error }">
                    <el-select v-model="portion.payment_method_id"
                               filterable
                               clearable
                               placeholder="Selecione um forma de pagamento"
                               empty-text="Nenhum registro encontrado"
                               class="el-select-full"
                               @input="$v.portion.payment_method_id.$touch()">
                        <el-option
                                v-for="item in dependencies.payment_methods"
                                :key="item.id"
                                :label="item.description"
                                :value="item.id">
                        </el-option>
                    </el-select>
                    <validation-message v-if="$v.portion.payment_method_id.$error"/>
                </el-form-item>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="Data Pagamento"
                                      class="is-required"
                                      v-bind:class="{ 'el-form-item--error': $v.portion.pay_date.$error }">
                            <el-date-picker
                                    v-model="portion.pay_date"
                                    type="date"
                                    format="dd/MM/yyyy"
                                    v-mask="'##/##/####'"
                                    placeholder="Pagamento"
                                    @input="$v.portion.pay_date.$touch()">
                            </el-date-picker>
                            <validation-message v-if="$v.portion.pay_date.$error"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Valor Pago"
                                      class="is-required"
                                      v-bind:class="{ 'el-form-item--error': $v.portion.amount_paid.$error }">
                            <el-input v-model.lazy="portion.amount_paid"
                                      v-money="moneyMask"
                                      @input="$v.portion.amount_paid.$touch()"/>
                            <validation-message v-if="$v.portion.amount_paid.$error"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="Descrição/Observações">
                    <el-input type="textarea"
                              :rows="4"
                              v-model="portion.description"/>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleSettleModalVisible">Cancelar</el-button>
                <el-button type="primary"
                           @click="savePortion(portion)"
                           :disabled="isSubmitted || $v.portion.$invalid"
                           :loading="isSubmitted">
                    {{isSubmitted ? 'Salvando' : 'Salvar'}} Alterações
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>
