<script>
import ListMain from '../../../../components/list/Main.vue'
import ListHeader from '../../../../components/list/Header.vue'
import ListEditButton from '../../../../components/list/EditButton.vue'
import ListDeleteButton from '../../../../components/list/DeleteButton.vue'
import ValidationMessage from '../../../../components/form/ValidationMessage.vue'
import StatusLabel from '../../../../components/common/StatusLabel.vue'
import AuthButton from './AuthButton.vue'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import {mapGetters} from 'vuex'

import {clone} from 'lodash'
import moment from 'moment'
import {required} from 'vuelidate/lib/validators'

const defaultSearchData = {
  orderBy: [{column: 'name', direction: 'asc'}],
  status: 'active'
}

const defaultFilters = {
  name: '',
  cpf_cnpj: '',
  gripo_enabled: 'all',
  blocked: 'all',
  status: 'active',
  generate_nfse: 'all',
  city_id: '',
  state_id: '',
  module: 'all',
  order_by: 'name#asc'
}

const modulesMapping = {
  tournament: 'Torneios',
  basic: 'Básico',
  professional: 'Profissional',
  totem: 'Totem',
  faceRecon: 'FaceID',
}

export default {
  components: {ListMain, ListHeader, ListEditButton, ListDeleteButton, ValidationMessage, StatusLabel, AuthButton},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      isSubmitted: false,
      isSearchingCity: false,
      filters: {...defaultFilters},
      dependencies: {
        cities: []
      },
      unblock: {
        client_id: '',
        reason: ''
      }
    }
  },
  validations: {
    unblock: {
      client_id: {required},
      reason: {required},
    }
  },
  mounted() {
    bus.$emit('list-init', {
      domain: 'client',
      relations: ['contacts', 'city.state', 'clubs.club', 'clubs.users.user', 'last_block', 'last_stage_entry', 'last_plan_entry'],
      data: clone(defaultSearchData)
    }, () => {
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore']),
    gestaoPath() {
      return `${process.env.VUE_APP_GESTAO_APP_URL}/auth/login/`
    },
    moment() {
      return moment()
    }
  },
  methods: {
    onSearchCity(query) {
      return new Promise((resolve, reject) => {
        this.dependencies.cities.splice(0)
        if (query !== '') {
          this.isSearchingCity = true
          let data = {query}
          restfulService.post('city', 'autocomplete', null, data)
              .then((response) => {
                this.dependencies.cities.splice(0)
                this.dependencies.cities = [...this.dependencies.cities, ...response]
                this.isSearchingCity = false
                resolve()
              })
              .catch(e => {
                console.log(e)
                reject()
              })
        }
      })
    },
    prepareSearchData() {
      const searchData = {}
      if (this.filters.name) {
        searchData.name = {
          'like': '%' + this.filters.name + '%'
        }
      }
      if (this.filters.cpf_cnpj) {
        searchData.cpf_cnpj = {
          'like': '%' + this.filters.cpf_cnpj + '%'
        }
      }
      if (this.filters.status !== 'all') {
        searchData.status = this.filters.status
      }
      if (this.filters.blocked !== 'all') {
        searchData.blocked = this.filters.blocked === 'yes'
      }
      if (this.filters.generate_nfse !== 'all') {
        searchData.generate_nfse = this.filters.generate_nfse === 'yes'
      }
      if (this.filters.state_id) {
        searchData.state_id = this.filters.state_id
      }
      if (this.filters.city_id) {
        searchData.city_id = this.filters.city_id
      }
      if (this.filters.module !== 'all') {
        searchData[`modules->${this.filters.module}`] = true
      }
      if (this.filters.order_by) {
        const [column, direction] = this.filters.order_by.split('#')
        searchData.orderBy = [{column, direction}]
      }
      return searchData
    },
    onFilter() {
      const searchData = this.prepareSearchData()
      bus.$emit('list-filter', searchData)
    },
    onFilterClean() {
      this.filters = {
        ...defaultFilters, ...{
          name: '',
          cpf_cnpj: '',
          blocked: 'all',
          status: 'active',
          generate_nfse: 'all'
        }
      }
      const searchData = this.prepareSearchData()
      bus.$emit('list-filter-clean', searchData)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    },
    showContacts(contacts, type) {
      if (!Array.isArray(contacts)) {
        return []
      }
      return contacts.filter(item => item.type === type).map(item => item.value).join(', ')
    },
    showClubs(clubs) {
      if (!Array.isArray(clubs)) {
        return []
      }
      return clubs.filter(item => item.club_id !== 1).map(item => item.club.name).join(', ')
    },
    clientUserAdmin(clubs) {
      return Array.isArray(clubs) && clubs.length && Array.isArray(clubs[0].users) && clubs[0].users.length
          ? clubs[0].users.find(item => item.user.roles.some(role => role.key === 'administrador'))?.user || {}
          : {}
    },
    clientModules(client) {
      return Object.entries(client.modules)
          .filter(item => item[1] === true)
          .map(item => modulesMapping[item[0]])
          .join(', ')
    },
    setClientUnblock(item) {
      this.unblock.client_id = item.id
    },
    onUnblock() {
      bus.$emit('show-loader')
      this.isSubmitted = true
      const data = {reason: this.unblock.reason}
      restfulService.put('client', 'unblock', this.unblock.client_id, data)
          .then(response => {
            notifyService.success({hideLoader: true})
            this.unblock.client_id = ''
            this.unblock.reason = ''
            this.isSubmitted = false
            this.onFilter()
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
    }
  },
  beforeMount() {
    this.listStore.listData = []
  }
}
</script>

<template>
  <div>

    <ListHeader title="Clientes" route-name="client.store"/>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtrar Listagem</span>
            <div>
              <el-button type="primary" @click="onFilter">Pesquisar</el-button>
              <el-button type="default" @click="onFilterClean">Limpar</el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters" class="demo-form-inline">
            <el-form-item label="Nome">
              <el-input v-model="filters.name"
                        placeholder="Filtrar por nome"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="CPF/CNPJ">
              <el-input v-model="filters.cpf_cnpj"
                        placeholder="Filtrar por CPF/CNPJ"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Status">
              <el-select v-model="filters.status">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Ativo" value="active"/>
                <el-option label="Inativo" value="inactive"/>
                <el-option label="Período de Testes" value="trial_time"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Módulos">
              <el-select v-model="filters.module">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Torneios" value="tournament"/>
                <el-option label="Gestão Básico" value="basic"/>
                <el-option label="Gestão Profissional" value="professional"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Cidade">
              <el-select v-model="filters.city_id"
                         clearable
                         filterable
                         remote
                         :remote-method="onSearchCity"
                         :loading="isSearchingCity"
                         placeholder="Selecione uma cidade"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full">
                <el-option v-for="item in dependencies.cities"
                           :key="item.id"
                           :label="item.name_with_state"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Bloqueado">
              <el-select v-model="filters.blocked">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Sim" value="yes"/>
                <el-option label="Não" value="no"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Gerar NFSe">
              <el-select v-model="filters.generate_nfse">
                <el-option label="Não filtrar" value="all"/>
                <el-option label="Sim" value="yes"/>
                <el-option label="Não" value="no"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Ordenar Por">
              <el-select v-model="filters.order_by">
                <el-option label="ID Asc" value="id#asc"/>
                <el-option label="ID Desc" value="id#desc"/>
                <el-option label="Nome Asc" value="name#asc"/>
                <el-option label="Nome Desc" value="name#desc"/>
                <el-option label="Dt. Cadastro Asc" value="created_at#asc"/>
                <el-option label="Dt. Cadastro Desc" value="created_at#desc"/>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Login</th>
            <th>CPF/CNPJ</th>
            <th>Módulos</th>
            <th>Contatos</th>
            <th v-if="false">Clubes</th>
            <th>Cidade/Estado</th>
            <th>Dt. Cadastro</th>
            <th>Dt. Ativação Plano</th>
            <th>Dt. Último Torneio</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in listStore.listData">
            <td>{{ item.id }}</td>
            <td>
              <div>{{ item.name }}</div>
              <div v-if="!item.blocked" class="mt-1">
                <status-label :status="item.status"/>
              </div>
              <div class="mt-2" v-if="item.blocked">
                <div>
                  <el-tag size="small" type="danger">Bloqueado</el-tag>
                </div>
                <div v-if="item.last_block">
                  Motivo:
                  <div><strong>{{ item.last_block.type === 'monthly_payment' ? 'Mensalidade' : 'Torneio' }}</strong></div>
                  Em:
                  <div><strong>{{ item.last_block.created_at | dateTimeEnToBr }}h</strong></div>
                </div>
              </div>
            </td>
            <td>
              <div class="mb-1">
                {{ clientUserAdmin(item.clubs)?.login || clientUserAdmin(item.clubs)?.email }}
              </div>
              <div>
                {{ item.database_name }}
              </div>
              <div v-if="item.database_name" style="max-width: 130px; overflow-wrap: break-word;">
                <auth-button :tenant-id="item.id" :tenant-user-id="clientUserAdmin(item.clubs)?.id"/>
              </div>
            </td>
            <td>{{ item.cpf_cnpj }}</td>
            <td>{{ clientModules(item) }}</td>
            <td width="200">
              <div style="max-width: 130px; overflow-wrap: break-word;">{{ showContacts(item.contacts, 'phone') }}</div>
              <div style="max-width: 130px; overflow-wrap: break-word;">{{ showContacts(item.contacts, 'email') }}</div>
            </td>
            <td v-if="false">{{ showClubs(item.clubs) }}</td>
            <td>{{ item.city ? item.city.name_with_state : '' }}</td>
            <td>{{ item.created_at | dateEnToBr }}</td>
            <td>
              <template v-if="item.last_plan_entry">
                <div>
                  {{ item.last_plan_entry.due_date | dateEnToBr }}
                </div>
                <div class="text-danger mt-1"  v-if="item.last_plan_entry.end_date_recurrent <= moment.format('YYY-MM-DD HH:mm:SS')">
                  Cancelamento: {{ item.last_plan_entry.end_date_recurrent | dateEnToBr }}
                </div>
              </template>
            </td>
            <td>
              <template v-if="item.last_stage_entry">
                {{ item.last_stage_entry.due_date | dateEnToBr }}
              </template>
            </td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <el-popover
                    v-if="item.blocked"
                    placement="left"
                    width="400"
                    trigger="click">
                  <el-button type="warning"
                             @click="setClientUnblock(item)"
                             title="Desbloquear Cliente"
                             class="mr-1"
                             slot="reference">
                    <i class="fas fa-unlock"></i>
                  </el-button>

                  <h4>Deseja realmente desbloquear esse cliente?</h4>

                  <el-form ref="form"
                           :model="unblock"
                           label-position="top">
                    <el-form-item label="Razão do Desbloqueio"
                                  class="is-required"
                                  :class="{ 'el-form-item--error': $v.unblock.reason.$error }">
                      <el-input v-model="unblock.reason"
                                @input="$v.unblock.reason.$touch()"/>
                      <validation-message v-if="$v.unblock.reason.$error"/>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="success"
                                 @click="onUnblock"
                                 :disabled="isSubmitted || $v.unblock.$invalid"
                                 :loading="isSubmitted">
                        <i class="fas fa-check"></i>
                        <span>{{ isSubmitted ? 'Salvando' : 'Salvar' }} Alterações</span>
                      </el-button>
                    </el-form-item>
                  </el-form>
                </el-popover>
                <ListEditButton route-name="client.update" :item="item"/>
                <ListDeleteButton :item="item" domain="client"/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
  </div>
</template>
