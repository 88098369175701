<script>
import ListHeader from '../../../../components/list/Header.vue'
import FormSaveButton from '../../../../components/form/SaveButton.vue'
import FormCancelButton from '../../../../components/form/CancelButton.vue'

import * as restfulService from '../../../../services/restfulService'
import * as notifyService from '../../../../services/notifyService'
import DataService from '../../../../services/dataService'

import bus from '@utils/bus'
import helpers from '@mixins/helpers'

import { required } from 'vuelidate/lib/validators'
import { clone } from 'lodash'

export default {
  components: {ListHeader, FormSaveButton, FormCancelButton},
  mixins: [helpers],
  data() {
    return {
      isEdit: false,
      isSubmitted: false,
      isLoadingData: true,
      form: {
        name: '',
        email: '',
        phone: '',
        password: '',
      },
      phonePrefix: '+55',
    }
  },
  validations: {
    form: {
      name: {required},
      email: {required},
    },
  },
  computed: {
    phoneMask() {
      return '## #####-####'
    }
  },
  mounted() {
    const id = this.$route.params.id || null
    if (id) {
      this.isEdit = true
      restfulService.get('admin_user', null, id)
          .then(response => {
            this.form = {...response}
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
    } else {
      this.isLoadingData = false
      bus.$emit('hide-loader')
    }
  },
  methods: {
    onSend() {
      this.isSubmitted = true
      const data = clone(this.form)
      if (data.phone) {
        data.phone = this.onlyNumbers(data.phone)
      }
      restfulService.save('admin_user', null, null, data)
          .then(() => {
            notifyService.success({hideLoader: true})
            this.isSubmitted = false
            this.$router.push({name: 'admin_user.index'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    }
  }
}
</script>

<template>
  <div v-if="!isLoadingData">

    <ListHeader title="Usuários"/>

    <el-form ref="form"
             :model="form"
             label-position="top"
             v-on:submit.prevent="onSend">
      <el-card class="el-card__save_form box-card">
        <div slot="header">
          <span>Dados do Usuário</span>
        </div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Nome">
              <el-input v-model="form.name"
                        @input="$v.form.name.$touch()"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="E-mail">
              <el-input type="email" v-model="form.email"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Phone">
              <el-input v-model="form.phone"
                        type="tel"
                        v-mask="phoneMask">
                <template slot="prepend">{{ phonePrefix }}</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Status"
                          class="is-required">
              <el-select v-model="form.status"
                         class="el-select-full">
                <el-option label="Ativo" value="active"/>
                <el-option label="Inativo" value="inactive"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Senha">
              <el-input v-model="form.password"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="el-card__btn_form">
        <el-form-item class="mb-0">
          <form-save-button :event-click="onSend"
                            :is-disabled="isSubmitted || $v.form.$invalid"
                            :is-submitted="isSubmitted"/>
          <form-cancel-button route-name="admin_user.index"/>
        </el-form-item>
      </el-card>
    </el-form>
  </div>
</template>
