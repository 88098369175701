<script>
  export default {
    name: 'validation-message',
    props: {
      message: {default: 'Esse campo é obrigatório', type: String}
    }
  }
</script>

<template>
    <div class="el-form-item__error">{{message}}</div>
</template>
