// Auth
export const AUTH_TENANT = 'AUTH_TENANT'
export const AUTH_SESSION = 'AUTH_SESSION'
export const AUTH_USER = 'AUTH_USER'
export const AUTH_TOKEN = 'AUTH_TOKEN'
export const AUTH_PERMISSIONS = 'AUTH_PERMISSIONS'
export const AUTH_ROLES = 'AUTH_ROLES'

// Global loader
export const LOADER_SHOW = 'LOADER_SHOW'
export const LOADER_MESSAGE = 'LOADER_MESSAGE'

// Sidebar
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'

// Snackbar notification
export const NOTIFICATION = 'NOTIFICATION'

// Main list component
export const LIST_OPTIONS = 'LIST_OPTIONS'
export const LIST_DATA = 'LIST_DATA'
export const LIST_SORT = 'LIST_SORT'

// Old Main list component
export const LIST_INIT = 'LIST_INIT'
export const LIST_ORIGINAL_DATA = 'LIST_ORIGINAL_DATA'
export const LIST_TOTAL = 'LIST_TOTAL'
export const LIST_PER_PAGE = 'LIST_PER_PAGE'
export const LIST_CURRENT_PAGE = 'LIST_CURRENT_PAGE'
export const LIST_FILTERS = 'LIST_FILTERS'
export const LIST_MODE = 'LIST_MODE'

