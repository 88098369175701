<script>
import DataService from "@/app/services/dataService";

import ListMain from '../../../../components/list/Main.vue'
import ListHeader from '../../../../components/list/Header.vue'

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import { mapGetters } from 'vuex'

import moment from 'moment'

const defaultFilters = {
  app: 'T',
  client_id: '',
  route_name: '',
  url: '',
  dates: [moment().subtract(10, 'minutes').format('YYYY-MM-DD HH:mm:SS'), moment().add(1, 'minutes').format('YYYY-MM-DD HH:mm:SS')],
  user_login: '',
  user_name: '',
  orderBy: [{column: 'created_at', direction: 'desc'}]
}

export default {
  components: {ListMain, ListHeader},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      filters: {...defaultFilters},
      dependencies: {
        tenants: []
      }
    }
  },
  async mounted() {
    this.getDependencies()
    const data = {...this.prepareSearchData()}
    bus.$emit('list-init', {
      domain: `log`,
      data,
      relations: ['client']
    }, () => {
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore'])
  },
  methods: {
    getDependencies() {
      DataService.get([{domain: 'client', columns: ['id', 'name']}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    prepareSearchData() {
      let searchData = {orderBy: defaultFilters.orderBy}
      if (this.filters.app !== 'T') {
        searchData.app = this.filters.app
      }
      if (this.filters.client_id) {
        searchData.client_id = this.filters.client_id.toString()
      }
      if (this.filters.route_name) {
        searchData.route_name = {
          like: `%${this.filters.route_name}%`
        }
      }
      if (Array.isArray(this.filters.dates) && this.filters.dates.length === 2) {
        searchData.datetime = {
          between: [...this.filters.dates]
        }
      }
      if (this.filters.url) {
        searchData.url = {
          like: `%${this.filters.url}%`
        }
      }
      if (this.filters.user_login) {
        searchData.user = {
          like: `%${this.filters.user_login}%`
        }
      }
      if (this.filters.user_name) {
        searchData.user = {
          like: `%${this.filters.user_name}%`
        }
      }
      return searchData
    },
    onFilter() {
      const data = this.prepareSearchData()
      bus.$emit('list-filter', data)
    },
    onFilterClean() {
      this.filters = {...defaultFilters}
      const defaultSearchData = this.prepareSearchData()
      bus.$emit('list-filter-clean', this.filters, defaultSearchData)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    }
  },
  beforeMount() {
    this.listStore.listData = []
  }
}
</script>

<template>
  <div>

    <ListHeader title="Logs"/>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtrar Listagem</span>
            <div>
              <el-button type="primary" @click="onFilter">Pesquisar</el-button>
              <el-button type="default" @click="onFilterClean">Limpar</el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Aplicação">
              <el-select v-model="filters.app"
                         placeholder="Filtrar por Aplicação"
                         empty-text="Nenhum registro encontrado">
                <el-option label="Todos" value="T"/>
                <el-option label="App" value="app"/>
                <el-option label="Tenant" value="tenant"/>
                <el-option label="Painel" value="painel"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Cliente">
              <el-select v-model="filters.client_id"
                         filterable
                         clearable
                         placeholder="Filtrar por Cliente"
                         empty-text="Nenhum registro encontrado">
                <el-option v-for="item in dependencies.clients"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Datas">
              <el-date-picker
                  v-model="filters.dates"
                  type="datetimerange"
                  format="dd/MM/yyyy HH:mm"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="Filtrar por datas">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Rota">
              <el-input v-model="filters.route_name"
                        placeholder="Filtrar por rota"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Url">
              <el-input v-model="filters.url"
                        placeholder="Filtrar por url"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Login">
              <el-input v-model="filters.user_login"
                        placeholder="Filtrar por login"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Usuário">
              <el-input v-model="filters.user_name"
                        placeholder="Filtrar por usuário"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th>App</th>
            <th>Data/Hora</th>
            <th>Cliente</th>
            <th>Rota</th>
            <th>Url</th>
            <th>Login</th>
            <th>Usuário</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in listStore.listData">
            <td>{{ item.app }}</td>
            <td>{{ item.created_at | dateTimeEnToBr }}h</td>
            <td>{{ item.client ? item.client.name : '' }}</td>
            <td>{{ item.route_name }}</td>
            <td style="max-width: 250px; word-break: break-all;">
              {{ item.url }}
            </td>
            <td>
              <span v-if="item.user">{{ item.user.login || item.user.email || item.user.phone }}</span>
            </td>
            <td>
              <span v-if="item.user">{{ item.user.name }}</span>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
  </div>
</template>
