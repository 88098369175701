import {routes as client} from './client'
import {routes as city} from './city'
import {routes as state} from './state'
import {routes as club} from './club'
import {routes as playerClass} from './player_class'

export default [
  ...client,
  ...city,
  ...state,
  ...club,
  ...playerClass
]
