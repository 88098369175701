<script>
  export default {
    name: 'status-label',
    props: ['status']
  }
</script>

<template>
    <div>
        <el-tag size="small" type="success" v-if="status === 'active'">Ativo</el-tag>
        <el-tag size="small" type="warning" v-if="status === 'inactive'">Inativo</el-tag>
        <el-tag size="small" type="default" v-if="status === 'not_confirmed'">Não confirmado</el-tag>
        <el-tag size="small" type="danger" v-if="status === 'blocked'">Bloqueado</el-tag>
    </div>
</template>
