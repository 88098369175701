<script>
import AppSidebar from './Sidebar.vue'
import AppToolbar from './Toolbar.vue'

import bus from '@utils/bus'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'App',
  components: {AppSidebar, AppToolbar},
  mounted() {
    bus.$on('show-loader', () => this.showLoader())
    bus.$on('hide-loader', () => this.hideLoader())
    if (document.documentElement.clientWidth <= 1280) {
      this.toggleSidebar()
    }
  },
  computed: {
    ...mapGetters(['isLogged', 'isLoading', 'showSidebar'])
  },
  methods: {
    ...mapActions(['showLoader', 'hideLoader', 'toggleSidebar']),
    checkSidebarToggle() {
      if (this.showSidebar) {
        this.toggleSidebar()
      }
    }
  }
}
</script>

<template>
  <el-container class="app-container"
                v-loading.fullscreen.lock="isLoading"
                element-loading-text="Carregando Dados...">
    <AppSidebar v-if="isLogged"/>
    <el-container>
      <el-header v-show="isLogged" class="app-toolbar">
        <AppToolbar/>
      </el-header>
      <el-main class="app-content"
               @click="checkSidebarToggle"
               :class="[{'has-sidebar': showSidebar}, {'is-logged': isLogged}]">
        <div :class="{ 'main-container': isLogged }">
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<style src="../../../assets/sass/app.scss" lang="scss"></style>
<style src="../../../assets/sass/responsive.scss" lang="scss"></style>
