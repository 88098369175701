import {http as HTTP} from '../../http'
import baseConfig from '../../config'

import {get as find} from '@utils/storage'

import Swal from 'sweetalert2'

const devEnvironment = process.env.NODE_ENV === 'development'

const defaultPage = 1
const defaultPerPage = 50

/**
 * Função que processa a url para utilizar o padrão da API
 * @param domain - Rota do Domain da API para realizar a requisição Ex.: clients
 * @param route - Complemento de rota Ex.: clients/all
 * @param id - Id do registro
 * @param columns - Colunas do model que devem ser retornadas
 * @param relations - Relacionamentos do model que devem ser retornados
 * @param page - Página atual da paginação
 * @param perPage - Número de registros que devem ser retornados por página
 * @returns string
 */
function _processUrl (domain, route, id, columns, relations, page, perPage) {
  let url = domain
  let hasUrlParams = false

  if (id !== undefined && id !== null) {
    url += '/' + id
  }
  url += (route ? '/' + route : '')
  url += '?q='

  // Inclui os dados da paginação na URL caso os parâmetros sejam passados
  if (page !== undefined && page !== null && page !== false) {
    page = page || defaultPage
    perPage = perPage || defaultPerPage
    url += '&_page=' + page + '&_limit=' + perPage
    hasUrlParams = true
  }

  // Inclui as colunas que devem retornar na requisição
  if (columns !== null && columns !== undefined && columns.length > 0) {
    columns.forEach(function (val) {
      if (val.length > 0) {
        url += '&_columns[]=' + val
      }
    })
    hasUrlParams = true
  }

  // Inclui os relacionamentos que devem retornar na requisição
  if (relations !== null && relations !== undefined && relations.length > 0) {
    relations.forEach(function (val) {
      if (val.length > 0) {
        url += '&_with[]=' + val
      }
    })
    hasUrlParams = true
  }
  if (!hasUrlParams) {
    url = url.replace('?q=', '')
  }
  return url
}

/**
 *
 * @param url
 * @param method
 * @param data
 * @returns {Promise<void>}
 */
async function logRequest (url, method, data) {
  if (devEnvironment) {
    data = data || {}
    const token = await find('token')
    const request = {
      path: [baseConfig.fullAPIPath, url].join('/'),
      auth: token ? 'Bearer ' + token : '',
      method: method,
      params: JSON.stringify(data)
    }
  }
}

/**
 * Função que faz uma requisição GET na API
 * @param domain - Rota do Domain da API para realizar a requisição Ex.: clients
 * @param route - Complemento de rota Ex.: clients/all
 * @param id - Id do registro
 * @param columns - Colunas do model que devem ser retornadas
 * @param relations - Relacionamentos do model que devem ser retornados
 * @returns Promise
 */
function _get (domain, route, id, columns, relations) {
  logRequest(_processUrl(domain, route, id, columns, relations), 'GET')
  return HTTP.get(_processUrl(domain, route, id, columns, relations)).then(response => response.data)
}

/**
 * Função que faz uma requisição POST na API
 * @param domain - Rota do Domain da API para realizar a requisição Ex.: clients
 * @param route - Complemento de rota Ex.: clients/all
 * @param id - Id do registro
 * @param data - Dados do POST
 * @returns Promise
 */
function _post (domain, route, id, data) {
  logRequest(_processUrl(domain, route, id), 'POST', data)
  return HTTP.post(_processUrl(domain, route, id), data).then(response => response.data)
}

/**
 * Função que faz uma requisição PUT na API
 * @param domain - Rota do Domain da API para realizar a requisição Ex.: clients
 * @param route - Complemento de rota Ex.: clients/all
 * @param id - Id do registro
 * @param data - Dados do POST
 * @returns Promise
 */
function _put (domain, route, id, data) {
  logRequest(_processUrl(domain, route, (id || data.id)), 'PUT', data)
  return HTTP.put(_processUrl(domain, route, (id || data.id)), data).then(response => response.data)
}

/**
 * Função que faz uma requisição POST/PUT na API
 * Caso existe o id no objeto data, a requisição sera PUT, caso não, será POST
 * @param domain - Rota do Domain da API para realizar a requisição Ex.: clients
 * @param route - Complemento de rota Ex.: clients/all
 * @param id - Id do registro
 * @param data - Dados do POST
 * @returns Promise
 */
function _save (domain, route, id, data) {
  if (data.id !== undefined && data.id !== null && data.id !== '' && data.id > 0) {
    return _put(domain, route, id, data)
  }
  return _post(domain, route, id, data)
}

/**
 * Função que faz uma requisição DELETE na API
 * @param domain
 * @param route
 * @param id
 * @returns Promise
 */
function _destroy (domain, route, id) {
  return new Promise(resolve => {
    Swal.fire({
      title: 'Deseja realmente remover esse item?',
      text: 'Essa ação não pode ser revertida!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, continuar!',
      cancelButtonText: 'Cancelar'
    })
      .then(async (result) => {
        if (result.value) {
          logRequest(_processUrl(domain, route, id), 'DELETE')
          await HTTP.delete(_processUrl(domain, route, id))
          resolve()
        }
      })
  })
}

/**
 * Busca todos os registros do domain
 * @param domain
 * @param route
 * @param id
 * @param columns
 * @param relations
 * @param page
 * @param perPage
 * @returns Promise
 */
function _all (domain, route, id, columns, relations, page, perPage) {
  logRequest(_processUrl(domain, route, id, columns, relations, page, perPage), 'GET')
  return HTTP.get(_processUrl(domain, route, id, columns, relations, page, perPage)).then(response => response.data)
}

/**
 * Busca dinâmica de registros do domain
 * @param domain
 * @param route
 * @param data
 * @param columns
 * @param relations
 * @param page
 * @param perPage
 * @param customSearch
 * @returns Promise
 */
function _search (domain, route, data, columns, relations, page, perPage, customSearch) {
  if (data === undefined || data === null) {
    data = null
  }
  page = page || defaultPage
  perPage = perPage || defaultPerPage
  logRequest(_processUrl(domain + (customSearch ? '' : '/search'), route, null, columns, relations, page, perPage), 'POST', data)
  return HTTP.post(_processUrl(domain + (customSearch ? '' : '/search'), route, null, columns, relations, page, perPage), data).then(response => response.data)
}

export const get = _get
export const post = _post
export const put = _put
export const save = _save
export const destroy = _destroy
export const all = _all
export const search = _search
