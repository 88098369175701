let basePath = process.env.VUE_APP_API_URL
let baseAPIGroup = 'painel'
let APIVersion = 'v1'

export default {
  basePath: basePath,
  baseAPIGroup: baseAPIGroup,
  APIVersion: APIVersion,
  fullAPIPath: [basePath, baseAPIGroup, APIVersion, ''].join('/')
}
