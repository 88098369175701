<script>
import { mapGetters, mapActions } from 'vuex'
import * as restfulService from '../../services/restfulService'
import * as notifyService from '../../services/notifyService'
import { clone } from 'lodash'

export default {
  name: 'app-toolbar',
  computed: {
    ...mapGetters(['currentUser'])
  },
  data() {
    return {
      isSubmitted: false,
      showChangePasswordModal: false,
      changePassword: {
        current_password: '',
        password: '',
        password_confirmation: ''
      }
    }
  },
  methods: {
    ...mapActions(['logout', 'toggleSidebar']),
    handleDropdownCommand(command) {
      if (command === 'changePassword') {
        this.showChangePasswordModal = true
      }
      if (command === 'logout') {
        this.onLogout()
      }
    },
    onChangePassword() {
      this.isSubmitted = true
      const data = clone(this.changePassword)
      restfulService.put('user', this.currentUser.id, 'change-password', data)
          .then(_ => {
            this.isSubmitted = false
            this.showChangePasswordModal = false
            this.changePassword = {}
            notifyService.success({hideLoader: true, message: 'A senha foi alterada'})
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
    },
    onLogout() {
      this.logout()
          .then(() => {
            this.$router.push({name: 'auth.login'})
          })
    }
  }
}
</script>

<template>
  <div class="app-toolbar-container">
    <el-button @click="toggleSidebar" type="text">
      <i class="fas fa-bars"></i>
    </el-button>
    <el-dropdown @command="handleDropdownCommand">
      <span class="el-dropdown-link">
          {{ currentUser.name }} <i class="el-icon-caret-bottom el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="changePassword">
          <span>Alterar Senha</span>
        </el-dropdown-item>
        <el-dropdown-item command="logout">
          <i class="fas fa-times-circle"></i>
          <span>Sair</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dialog title="Alteração de senha"
               width="40%"
               :visible.sync="showChangePasswordModal"
               v-loading.body="isSubmitted"
               element-loading-text="Gravando dados...">
      <el-form ref="form" :model="changePassword" label-width="120px">
        <el-form-item label="Senha atual">
          <el-input type="password" v-model="changePassword.current_password"></el-input>
        </el-form-item>
        <el-form-item label="Senha">
          <el-input type="password" v-model="changePassword.password"></el-input>
        </el-form-item>
        <el-form-item label="Confirmação">
          <el-input type="password" v-model="changePassword.password_confirmation"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     :disabled="isSubmitted"
                     @click="onChangePassword">Salvar nova senha
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
