<script>
import ListMain from '../../../../components/list/Main.vue'
import ListHeader from '../../../../components/list/Header.vue'
import NotificationUserType from './UserType'

import DataService from "@/app/services/dataService";

import helpers from '@mixins/helpers'
import bus from '@utils/bus'
import { mapGetters } from 'vuex'

import moment from 'moment'

const defaultFilter = {
  dates: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
  owner_type: 'T',
  channel: 'T',
  status: ['queued', 'sent', 'delivered', 'read'],
  tenant_id: ''
}
const defaultOrder = [{column: 'created_at', direction: 'desc'}]

export default {
  components: {ListMain, ListHeader, NotificationUserType},
  mixins: [helpers],
  data() {
    return {
      isLoadingData: true,
      filters: {...defaultFilter},
      dependencies: {
        tenants: []
      }
    }
  },
  async mounted() {
    this.getDependencies()
    const data = this.prepareSearchData()
    bus.$emit('list-init', {
      domain: `notification`,
      data,
    }, () => {
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore'])
  },
  methods: {
    getDependencies() {
      DataService.get([{domain: 'client', columns: ['id', 'name']}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    prepareSearchData() {
      let searchData = {orderBy: [...defaultOrder]}
      if (this.filters.status.length) {
        searchData.status = {
          in: this.filters.status
        }
      }
      if (this.filters.tenant_id) {
        searchData.tenant_id = this.filters.tenant_id
      }
      if (this.filters.message_key !== 'T') {
        searchData.message_key = this.filters.message_key
      }
      if (this.filters.owner_type !== 'T') {
        searchData.owner_type = this.filters.owner_type
      }
      if (this.filters.channel !== 'T') {
        searchData.channel = this.filters.channel
      }
      if (this.filters.target) {
        searchData.target = {
          like: `%${this.filters.target}%`
        }
      }
      if (this.filters.title) {
        searchData.title = {
          like: `%${this.filters.title}%`
        }
      }
      if (this.filters.message) {
        searchData.message = {
          like: `%${this.filters.message}%`
        }
      }
      if (this.filters.dates && this.filters.dates.length === 2) {
        if (this.filters.dates[0].length > 0 && this.filters.dates[1].length > 0) {
          searchData.sent_at = {
            between: [this.filters.dates[0] + ' 00:00:00', this.filters.dates[1] + ' 23:59:59']
          }
        }
      }
      if (this.filters.id) {
        searchData._id = {
          like: `%${this.filters.id}%`
        }
      }
      return searchData
    },
    onFilter() {
      const data = this.prepareSearchData()
      bus.$emit('list-filter', data)
    },
    onFilterClean() {
      this.filters = {...defaultFilter}
      const defaultSearchData = this.prepareSearchData()
      bus.$emit('list-filter-clean', this.filters, defaultSearchData)
    },
    onDestroy(item) {
      bus.$emit('list-destroy-item', item)
    }
  },
  beforeMount() {
    this.listStore.listData = []
  }
}
</script>

<template>
  <div>

    <ListHeader title="Notificações"/>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtrar Listagem</span>
            <div>
              <el-button type="primary" @click="onFilter">Pesquisar</el-button>
              <el-button type="default" @click="onFilterClean">Limpar</el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters">
            <el-form-item label="Cliente">
              <el-select v-model="filters.tenant_id"
                         filterable
                         clearable
                         placeholder="Filtrar por Cliente"
                         empty-text="Nenhum registro encontrado">
                <el-option v-for="item in dependencies.clients"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Status">
              <el-select v-model="filters.status"
                         clearable
                         multiple
                         placeholder="Filtrar por Status"
                         empty-text="Nenhum registro encontrado">
                <el-option label="Em fila" value="queued"/>
                <el-option label="Enviada" value="sent"/>
                <el-option label="Entregue" value="delivered"/>
                <el-option label="Lida" value="read"/>
                <el-option label="Não Entregue" value="undelivered"/>
                <el-option label="Falhou" value="failed"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Tipo de Usuário">
              <el-select v-model="filters.owner_type"
                         filterable
                         clearable
                         placeholder="Filtrar por Tipo de Usuário"
                         empty-text="Nenhum registro encontrado">
                <el-option label="Todos" value="T"/>
                <el-option label="App" value="Domain\Admin\System\User\User"/>
                <el-option label="Tenant" value="Domain\Admin\System\TenantUser\TenantUser"/>
                <el-option label="Outros" value="Domain\Admin\System\AdminUser\AdminUser"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Canal">
              <el-select v-model="filters.channel"
                         filterable
                         clearable
                         placeholder="Filtrar por canal"
                         empty-text="Nenhum registro encontrado">
                <el-option label="Todos" value="T"/>
                <el-option label="Whatsapp" value="whatsapp"/>
                <el-option label="App" value="push"/>
                <el-option label="E-mail" value="email"/>
                <el-option label="Sms" value="sms"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Data de Envio">
              <el-date-picker
                  v-model="filters.dates"
                  type="daterange"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  placeholder="Filtrar por data de envio">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="Destino">
              <el-input v-model="filters.target"
                        placeholder="Filtrar por destino"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Título">
              <el-input v-model="filters.title"
                        placeholder="Filtrar por título"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Mensagem">
              <el-input v-model="filters.message"
                        placeholder="Filtrar por mensagem"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Id da Notificação">
              <el-input v-model="filters.id"
                        placeholder="Filtrar por id"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th>Data/Hora do Envio</th>
            <th>Canal</th>
            <th>Tipo de Usuário</th>
            <th>Destino</th>
            <th>Título</th>
            <th>Mensagem</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in listStore.listData">
            <td>{{ item.sent_at | dateEnToBr }} {{ item.sent_at | timeEnToBr }}h</td>
            <td>{{ item.channel }}</td>
            <td class="text-center">
              <notification-user-type :owner-type="item.owner_type"/>
            </td>
            <td>{{ item.target }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.message }}</td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
  </div>
</template>
