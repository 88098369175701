<script>
import ListMain from '../../../../components/list/Main.vue'
import ListHeader from '../../../../components/list/Header.vue'
import ListEditButton from '../../../../components/list/EditButton.vue'

import bus from '@utils/bus'
import { mapGetters } from 'vuex'

import DataService from '@/app/services/dataService';

const defaultSort = {orderBy: [{column: 'sex', direction: 'asc'}, {column: 'name', direction: 'asc'}]}

export default {
  components: {ListMain, ListHeader, ListEditButton},
  data() {
    return {
      isLoadingData: true,
      filters: {
        name: '',
        sex: '',
      },
      dependencies: {
        player_class_sex: []
      }
    }
  },
  mounted() {
    this.getDependencies()
    bus.$emit('list-init', {domain: 'player_class', data: {...defaultSort}}, () => {
      this.isLoadingData = false
    })
  },
  computed: {
    ...mapGetters(['listStore'])
  },
  methods: {
    getDependencies() {
      DataService.get([{domain: 'player_class_sex'}]).then((result) => {
        this.dependencies = {...this.dependencies, ...result}
      })
    },
    onFilter() {
      const searchData = {}
      if (this.filters.name) {
        searchData.name = {
          'like': '%' + this.filters.name + '%'
        }
      }
      if (this.filters.sex) {
        searchData.sex = this.filters.sex
      }
      bus.$emit('list-filter', searchData, this.filters)
    },
    onFilterClean() {
      this.filters = {}
      bus.$emit('list-filter-clean', {...defaultSort})
    }
  },
  beforeMount() {
    this.listStore.listData = []
  }
}
</script>

<template>
  <div>

    <ListHeader title="Categorias" route-name="player_class.store"/>

    <ListMain>

      <div slot="filters">

        <el-card class="el-card__search_list box-card">
          <div slot="header">
            <span>Filtrar Listagem</span>
            <div>
              <el-button type="primary" @click="onFilter">Pesquisar</el-button>
              <el-button type="default" @click="onFilterClean">Limpar</el-button>
            </div>
          </div>
          <el-form :inline="true" :model="filters" class="demo-form-inline">
            <el-form-item label="Nome">
              <el-input v-model="filters.name"
                        placeholder="Filtrar por nome"
                        @keydown.enter.native.prevent="onFilter"/>
            </el-form-item>
            <el-form-item label="Sexo">
              <el-select v-model="filters.sex"
                         @keydown.enter.native.prevent="onFilter"
                         clearable
                         placeholder="Selecione um sexo"
                         empty-text="Nenhum registro encontrado"
                         class="el-select-full">
                <el-option v-for="item in dependencies.player_class_sex"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-card>

      </div>

      <div slot="list" v-if="!isLoadingData">

        <table class="custom-table-list">
          <thead>
          <tr>
            <th>ID</th>
            <th>Sexo</th>
            <th>Nome</th>
            <th class="btn-actions">Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in listStore.listData">
            <td>{{ item.id }}</td>
            <td>{{ item.sex_label }}</td>
            <td>{{ item.name }}</td>
            <td class="list-table-nowrap">
              <div class="btn-actions">
                <ListEditButton route-name="player_class.update" :item="item"/>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>

    </ListMain>
  </div>
</template>
