<script>
  import ListHeader from '../../../../components/list/Header.vue'
  import FormSaveButton from '../../../../components/form/SaveButton.vue'
  import FormCancelButton from '../../../../components/form/CancelButton.vue'

  import * as restfulService from '../../../../services/restfulService'
  import * as notifyService from '../../../../services/notifyService'

  import bus from '@utils/bus'

  import { required } from 'vuelidate/lib/validators'
  import { clone } from 'lodash'

  export default {
    components: { ListHeader, FormSaveButton, FormCancelButton },
    data () {
      return {
        isEdit: false,
        isSubmitted: false,
        isLoadingData: true,
        isSearchingCity: false,
        form: {
          name: '',
          initials: ''
        },
        dependencies: {
          cities: []
        }
      }
    },
    validations: {
      form: {
        name: { required },
        city_id: { required }
      }
    },
    mounted () {
      const id = this.$route.params.id || null
      if (id) {
        this.isEdit = true
        restfulService.get('club', null, id, null, ['city'])
          .then(response => {
            this.form = { ...response }
            if (this.form.city) {
              this.dependencies.cities.push(this.form.city)
            }
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
          .catch(e => {
            console.log(e)
            this.isLoadingData = false
            bus.$emit('hide-loader')
          })
      } else {
        this.isLoadingData = false
        bus.$emit('hide-loader')
      }
    },
    methods: {
      onSearchCity (query) {
        return new Promise((resolve, reject) => {
          this.dependencies.cities.splice(0)
          if (query !== '') {
            this.isSearchingCity = true
            let data = { query }
            restfulService.post('city', 'autocomplete', null, data)
              .then((response) => {
                this.dependencies.cities.splice(0)
                this.dependencies.cities = [...this.dependencies.cities, ...response]
                this.isSearchingCity = false
                resolve()
              })
              .catch(e => {
                console.log(e)
                reject()
              })
          }
        })
      },
      onSend () {
        this.isSubmitted = true
        const data = clone(this.form)
        restfulService.save('club', null, null, data)
          .then(() => {
            notifyService.success({ hideLoader: true })
            this.isSubmitted = false
            this.$router.push({ name: 'club.index' })
          })
          .catch(e => {
            this.isSubmitted = false
            console.log(e)
          })
      }
    }
  }
</script>

<template>
    <div v-if="!isLoadingData">

        <ListHeader title="Clubes"/>

        <el-card class="el-card__save_form box-card">
            <div slot="header">
                <span>Dados dos Clubes</span>
            </div>
            <el-form ref="form"
                     :model="form"
                     label-width="120px"
                     v-on:submit.prevent="onSend">
                <el-form-item label="Nome"
                              class="is-required"
                              v-bind:class="{ 'el-form-item--error': $v.form.name.$error }">
                    <el-input v-model="form.name"
                              @input="$v.form.name.$touch()"/>
                    <div class="el-form-item__error" v-if="$v.form.name.$error">Esse campo é obrigatório</div>
                </el-form-item>
                <el-form-item label="Cidade"
                              class="is-required"
                              v-bind:class="{ 'el-form-item--error': $v.form.city_id.$error }">
                    <el-select v-model="form.city_id"
                               clearable
                               filterable
                               remote
                               :remote-method="onSearchCity"
                               :loading="isSearchingCity"
                               placeholder="Selecione uma cidade"
                               empty-text="Nenhum registro encontrado"
                               class="el-select-full"
                               @input="$v.form.city_id.$touch()">
                        <el-option v-for="item in dependencies.cities"
                                   :key="item.id"
                                   :label="item.name_with_state"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <div class="el-form-item__error" v-if="$v.form.city_id.$error">
                        <span v-if="!$v.form.city_id.required">Esse campo é obrigatório</span>
                    </div>
                </el-form-item>
                <el-form-item>
                    <form-save-button :event-click="onSend"
                                      :is-disabled="isSubmitted || $v.$invalid"
                                      :is-submitted="isSubmitted"/>
                    <form-cancel-button route-name="club.index"/>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
