<script>
  export default {
    name: 'list-header',
    props: ['title', 'routeName', 'backRoute', 'routeEditName', 'item', 'storeTitle']
  }
</script>

<template>
    <div class="app-header">
        <h2>{{title}}</h2>
        <div>
            <slot></slot>
            <router-link :to="{ name: routeName }" v-if="routeName" class="el-button el-button--success">
                <i class="fas fa-plus-circle"></i>
                <span>{{storeTitle ? storeTitle : 'Criar ' + title}}</span>
            </router-link>
            <router-link :to="{ name: routeEditName, params: { id: item.id } }"
                         v-if="routeEditName && item"
                         class="el-button el-button--info">
                <i class="fas fa-edit"></i>
                <span>Editar</span>
            </router-link>
            <router-link :to="{ name: backRoute }" v-if="backRoute" class="el-button el-button--default">
                <i class="fas fa-arrow-left"></i>
                <span>Voltar</span>
            </router-link>
        </div>
    </div>
</template>
