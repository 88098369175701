<script>
  import * as restfulService from '../../../../services/restfulService'

  import bus from '@utils/bus'
  import {mapActions} from 'vuex'

  import {required} from 'vuelidate/lib/validators'
  import {clone} from 'lodash'

  export default {
    data () {
      return {
        isSubmitted: false,
        isLoadingData: true,
        form: {
          login: '',
          password: ''
        }
      }
    },
    validations: {
      form: {
        login: {required},
        password: {required}
      }
    },
    mounted () {
      setTimeout(() => {
        bus.$emit('hide-loader')
      }, 300)
    },
    methods: {
      ...mapActions(['login']),
      onLogin () {
        bus.$emit('show-loader')
        this.isSubmitted = true
        const data = clone(this.form)
        restfulService.post('auth', 'login', null, data)
          .then((response) => {
            this.login(response).then(() => {
              this.isSubmitted = false
              this.$router.push({name: 'client.index'})
            })
          })
          .catch(e => {
            bus.$emit('hide-loader')
            this.isSubmitted = false
            console.log(e)
          })
      }
    }
  }
</script>

<template>
    <div class="login-box">

        <div class="app-header">
            <h2>ÁREA RESTRITA</h2>
        </div>

        <el-card class="el-card__save_form box-card">
            <el-form ref="form"
                     :model="form"
                     label-position="top"
                     v-on:submit.prevent="onLogin">
                <el-form-item label="Login/Email/Telefone"
                              class="is-required"
                              v-bind:class="{ 'el-form-item--error': $v.form.login.$error }">
                    <el-input v-model="form.login"
                              size="large"
                              @input="$v.form.login.$touch()"
                              @keydown.enter.native.prevent="onLogin"/>
                    <div class="el-form-item__error" v-if="$v.form.login.$error">Esse campo é obrigatório</div>
                </el-form-item>
                <el-form-item label="Senha"
                              class="is-required"
                              v-bind:class="{ 'el-form-item--error': $v.form.password.$error }">
                    <el-input v-model="form.password"
                              size="large"
                              type="password"
                              @input="$v.form.password.$touch()"
                              @keydown.enter.native.prevent="onLogin"/>
                    <div class="el-form-item__error" v-if="$v.form.password.$error">Esse campo é obrigatório</div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               size="large"
                               @click="onLogin"
                               :disabled="isSubmitted || $v.$invalid">Acessar
                    </el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<style lang="scss">
    .login-box {
        width: 500px;
        margin: 0 auto;

        .app-header h2 {
            width: 100%;
            display: block;
            text-align: center;
            padding: 25px 0 40px;
        }

        .box-card {
            padding: 40px;
        }

        .el-form {
            .el-form-item:last-child {
                margin-bottom: 0;
            }

            button {
                width: 100%;
                display: block;
                padding: 15px 0;
            }
        }
    }
</style>
