<script>
  export default {
    name: 'entry-status-label',
    props: ['status']
  }
</script>

<template>
    <div>
        <el-tag size="small" type="default" v-if="status === 'created'">Aberto</el-tag>
        <el-tag size="small" type="success" v-if="status === 'settled'">Quitado</el-tag>
    </div>
</template>
