import moment from 'moment'
import * as MESSAGES from '../app/messages'

export default {
  methods: {
    dateNow: function (format) {
      format = format || 'br'
      return moment().format((format === 'br' ? 'DD/MM/YYYY' : 'YYYY-MM-DD'))
    },
    dateTimeNow: function (short) {
      short = short || false
      return short ? moment().format('HH:mm') : moment().format('HH:mm:SS')
    },
    dateFormat: function (date, format) {
      if (date === undefined || date === null || date.length < 1) {
        return ''
      }
      format = format || 'br'
      if (format === 'br') {
        return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
      } else if (format === 'en') {
        return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
    },
    dateTimeFormat: function (datetime, short) {
      if (datetime === undefined || datetime === null || datetime.length < 1) {
        return ''
      }
      short = short || false
      if (datetime.indexOf(' ') !== -1) {
        let timeSplit = datetime.split(' ')
        datetime = timeSplit[1]
      }
      return short ? moment(datetime, 'HH:mm:SS').format('DD/MM/YYYY HH:mm') : moment(datetime, 'HH:mm:SS').format('DD/MM/YYYY HH:mm:SS')
    },
    dateTimeToTimeFormat: function (datetime, short) {
      if (datetime === undefined || datetime === null || datetime.length < 1) {
        return ''
      }
      short = short || false
      let resultFormat = (short ? 'HH:mm' : 'HH:mm:ss')
      return moment(datetime).format(resultFormat)
    },
    dateFirstDayOfMonth: function (formatBr) {
      if (!formatBr) {
        return moment(moment().format('YYYY-MM-01'), 'YYYY-MM-DD').toDate()
      } else {
        return moment(moment().format('01/MM/YYYY'), 'DD/MM/YYYY').toDate()
      }
    },
    dateLastDayOfMonth: function (formatBr) {
      if (!formatBr) {
        return moment(moment().format('YYYY-MM-') + moment().daysInMonth(), 'YYYY-MM-DD').toDate()
      } else {
        return moment(moment().daysInMonth() + moment().format('/MM/YYYY'), 'DD/MM/YYYY').toDate()
      }
    },
    dateCalendarToString: function (date) {
      if (date === undefined || date === null || date.length < 1) {
        return ''
      }
      if (date.indexOf('T') > -1) {
        let dateSplit = date.split('T')
        return dateSplit[0]
      }
      return date
    },
    dateRemoveTime: function (date) {
      const newDate = date.split(' ')
      return newDate[0]
    },
    currencyBrToEn: function (number) {
      if (number !== undefined && number !== false) {
        number = number.toString()
        let newValue = number.replace('R$', '').replace('r$', '')
        if (newValue.indexOf(',') > -1) {
          let replaced = newValue.replace(/[.]/g, '')
          newValue = replaced.replace(',', '.')
        }
        return parseFloat(newValue)
      }
      return number
    },
    currencyEnToBr: function (number) {
      if (number !== undefined && number !== null) {
        return this.numberFormat(number, 2, ',', '.')
      }
      return number
    },
    numberFormat: function (numberValue, decimals, decPoint, thousandsSep) {
      let number = numberValue
      number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
      let n = !isFinite(+number) ? 0 : +number
      let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
      let sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
      let dec = (typeof decPoint === 'undefined') ? '.' : decPoint

      let toFixedFix = function (n, prec) {
        let k = Math.pow(10, prec)
        return '' + (Math.round(n * k) / k).toFixed(prec)
      }

      let s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
      }
      if ((s[1] || '').length < prec) {
        s[1] = s[1] || ''
        s[1] += new Array(prec - s[1].length + 1).join('0')
      }

      return s.join(dec)
    },
    onlyNumbers (str) {
      if (str === undefined || str === null) {
        return str
      }
      if (typeof str !== 'string') {
        str = str.toString()
      }
      return str.replace(/\D+/g, '')
    },
    strPadLeft (str, size, char = '0') {
      return String(char.repeat(size) + str).slice(str.length * -1)
    },
    fixCpfLength (cpf) {
      return this.strPadLeft(this.onlyNumbers(cpf), 11)
    },
    roundLower (value, decimals) {
      let aux = Math.pow(10, decimals)
      return Math.floor(value * aux) / aux
    },
    weekdayString (weekday) {
      switch (weekday) {
        case 0:
          return 'Domingo'
        case 1:
          return 'Segunda-Feira'
        case 2:
          return 'Terça-Feira'
        case 3:
          return 'Quarta-Feira'
        case 4:
          return 'Quinta-Feira'
        case 5:
          return 'Sexta-Feira'
        case 6:
          return 'Sábado'
      }
    },
    validationMessageField (field, types, value = null) {
      types = types || ['required']
      const errors = []
      if (!field.$dirty) return errors
      if (types.indexOf('required') !== -1) {
        !field.required && errors.push(MESSAGES.VALIDATION_REQUIRED)
      }
      if (types.indexOf('validEmail') !== -1) {
        !field.email && errors.push(MESSAGES.VALIDATION_EMAIL)
      }
      if (types.indexOf('minLength') !== -1) {
        !field.minLength && errors.push(MESSAGES.VALIDATION_MINLENGTH + field.$params.minLength.min)
      }
      if (types.indexOf('maxLength') !== -1) {
        !field.maxLength && errors.push(MESSAGES.VALIDATION_MAXLENGTH + field.$params.maxLength.max)
      }
      return errors
    }
  }
}
