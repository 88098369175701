<script>
  import ListMain from '../../../../components/list/Main.vue'
  import ListHeader from '../../../../components/list/Header.vue'
  import ListEditButton from '../../../../components/list/EditButton.vue'
  import ListDeleteButton from '../../../../components/list/DeleteButton.vue'

  import bus from '@utils/bus'
  import {mapGetters} from 'vuex'

  import {isEmpty} from 'lodash'

  export default {
    components: {ListMain, ListHeader, ListEditButton, ListDeleteButton},
    data () {
      return {
        isLoadingData: true,
        filters: {},
        dependencies: {}
      }
    },
    mounted () {
      bus.$emit('list-init', {domain: 'state'}, () => {
        this.isLoadingData = false
      })
    },
    computed: {
      ...mapGetters(['listStore'])
    },
    methods: {
      onFilter () {
        const searchData = {}
        if (!isEmpty(this.filters.name)) {
          searchData.name = {
            'like': '%' + this.filters.name + '%'
          }
        }
        bus.$emit('list-filter', this.filters, searchData)
      },
      onFilterClean () {
        this.filters = {}
        bus.$emit('list-filter-clean', this.filters)
      },
      onDestroy (item) {
        bus.$emit('list-destroy-item', item)
      }
    },
    beforeMount () {
      this.listStore.listData = []
    }
  }
</script>

<template>
    <div>

        <ListHeader title="Estados" route-name="state.store"/>

        <ListMain>

            <div slot="filters">

                <el-card class="el-card__search_list box-card">
                    <div slot="header">
                        <span>Filtrar Listagem</span>
                        <div>
                            <el-button type="primary" @click="onFilter">Pesquisar</el-button>
                            <el-button type="default" @click="onFilterClean">Limpar</el-button>
                        </div>
                    </div>
                    <el-form :inline="true" :model="filters" class="demo-form-inline">
                        <el-form-item label="Nome">
                            <el-input v-model="filters.name" placeholder="Filtrar por nome"/>
                        </el-form-item>
                    </el-form>
                </el-card>

            </div>

            <div slot="list" v-if="!isLoadingData">

                <h2>Lista de Estados</h2>
                <table class="custom-table-list">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>UF</th>
                        <th class="btn-actions">Ações</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in listStore.listData">
                        <td>{{item.id}}</td>
                        <td>{{item.name}}</td>
                        <td>{{item.initials}}</td>
                        <td class="list-table-nowrap">
                            <div class="btn-actions">
                                <ListEditButton route-name="state.update" :item="item"/>
                                <ListDeleteButton :item="item" domain="state"/>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>

        </ListMain>
    </div>
</template>
