import store from '../vuex'
import bus from '@utils/bus'
import {get} from '@utils/storage'

import Swal from 'sweetalert2'

const hasPermission = (route) => {
  return true
  /*
  let permissionName = route.meta.permission || route.name
  return store.getters.permissions.some((item) => {
    return item.name === permissionName
  })
  */
}

export default async (to, from, next) => {
  // Position the scroll on top
  window.scrollTo(0, 0)

  // Show global loader
  bus.$emit('show-loader')

  // Check if route needs auth
  if (to.meta.auth === false) {
    return next()
  }

  // Get auth data from storage
  const token = await get('token')
  const user = await get('user')
  const permissions = await get('permissions')
  const tenant = await get('tenant')
  const session = await get('session')

  // If token doesn't exists, redirecto to auth
  if (!token) {
    return next({name: 'auth.login'})
  }

  // Store in vuex user data
  const data = {
    token: token,
    user: user,
    permissions: permissions,
    tenant: tenant,
    session: session
  }
  await store.dispatch('setUpAuth', data)

  // Check route permissions
  if (hasPermission(to) === false) {
    Swal.fire({
      title: 'Atenção',
      html: 'Acesso negado a esse recurso',
      type: 'warning'
    })
    console.log('Acesso negado', to.name)
    return next(false)
  }

  return next()
}
